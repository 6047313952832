import React from 'react'
import PropTypes from 'prop-types'
import { isNil, slice, isEmpty, toUpper } from 'lodash'

import useLoadImage from '../../../../helpers/useLoadImage'
import { LinkButton } from '../../../kit'
import {
  CardContainer,
  BannerImage,
  CardContentContainer,
  DescriptionContainer,
  TitleContainer,
  Description,
  Label,
  Title,
  CardTextContainer,
} from './styles'
import ProductSkeleton from './Skeleton'

type Props = {
  linkButtonLabel?: string
  url?: string
  imageUrl: string
  type?: string
  small: boolean
  title: string
  description: string
}

const ProductCard = ({ type, title, description, linkButtonLabel, url, imageUrl, small }: Props) => {
  const isImageLoaded = useLoadImage(imageUrl)

  return !isImageLoaded && small && !isNil(imageUrl) ? (
    <ProductSkeleton />
  ) : (
    <CardContainer small={small}>
      <BannerImage style={{ background: `url(${imageUrl}) no-repeat` }} small={small} />
      <CardContentContainer small={small}>
        <CardTextContainer small={small}>
          <Label small={small}>{toUpper(type)}</Label>
          <TitleContainer small={small}>
            <Title small={small}>{title}</Title>
          </TitleContainer>
          <DescriptionContainer>
            {!isEmpty(description) && (
              <Description small={small}>
                {description.length > 124 ? slice(description, 0, 125).concat('...') : description}
              </Description>
            )}
          </DescriptionContainer>
        </CardTextContainer>
        {!isNil(linkButtonLabel) && !isNil(url) && (
          <div>
            <LinkButton label={linkButtonLabel} url={url} hasIcon={true} small={small} />
          </div>
        )}
      </CardContentContainer>
    </CardContainer>
  )
}

ProductCard.defaultProps = {
  linkButtonLabel: undefined,
  url: undefined,
  type: undefined,
  small: false,
}

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkButtonLabel: PropTypes.string,
  small: PropTypes.bool,
  url: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default ProductCard
