import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'

import * as S from './styles'

type CardPriceProps = {
  onSelect: () => void
  selected: boolean
  title: string
  price: number
  description: string
  currency: string
  recurrence: string
  type: string
  desktop?: boolean
}

const CardPrice = ({
  onSelect,
  selected,
  title,
  price,
  description,
  currency,
  recurrence,
  type,
  desktop,
}: CardPriceProps) => {
  const { t } = useTranslation()
  const themeContext = useContext(ThemeContext)
  const { color_main: mainColor } = themeContext

  return (
    <S.Container selected={selected} onClick={onSelect}>
      {selected ? (
        <div>
          <S.RadioButton xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
            <circle cx="8.5" cy="8.5" r="4.857" fill={mainColor} />
            <circle cx="8.5" cy="8.5" r="7.75" stroke={mainColor} strokeWidth="1.5" />
          </S.RadioButton>
        </div>
      ) : (
        <div>
          <S.RadioButton xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
            <circle cx="8.5" cy="8.5" r="7.75" stroke="#241D29" strokeWidth="1.5" />
          </S.RadioButton>
        </div>
      )}

      {desktop ? (
        <S.InfoWrapperDesktop>
          <S.TextWrapper>
            <S.Title>{title}</S.Title>
            <S.Description>{description}</S.Description>
          </S.TextWrapper>
          <S.DescriptionWrapper>
            <S.Price>
              {type === 'FREE'
                ? t('cardPrice.free')
                : type === 'ONE_TIME'
                ? `${currency} ${price}`
                : `${currency} ${price} / ${recurrence === 'MONTHLY' ? t('cardPrice.month') : t('cardPrice.annual')}`}
            </S.Price>
            <S.Description>
              {type === 'FREE'
                ? ''
                : type === 'ONE_TIME'
                ? t('cardPrice.uniquePay')
                : recurrence === 'MONTHLY'
                ? t('cardPrice.membership')
                : t('cardPrice.membership_annual')}
            </S.Description>
          </S.DescriptionWrapper>
        </S.InfoWrapperDesktop>
      ) : (
        <S.InfoWrapper>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
          <S.Price>
            {type === 'FREE'
              ? t('cardPrice.free')
              : type === 'ONE_TIME'
              ? `${currency} ${price}`
              : `${currency} ${price} / ${recurrence === 'MONTHLY' ? t('cardPrice.month') : t('cardPrice.annual')}`}
          </S.Price>
        </S.InfoWrapper>
      )}
    </S.Container>
  )
}
export default CardPrice
