import styled from 'styled-components'

type Props = {
  theme: any
}

export const FormInput = styled.input`
  margin-top: 7px;
  width: -webkit-fill-available;
  padding: 0 20px;
  box-shadow: 0 7px 35px 0 rgba(85, 87, 87, 0.04);
  border: solid 1px rgba(237, 237, 237, 0.86);
  background-color: #fefeff;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;

  :focus {
    border: solid 1px ${(props: Props) => props.theme.color_main};
    outline: none;
  }
`

export const InputError = styled.p`
  color: red;
`
