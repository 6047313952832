import styled from 'styled-components'
import { COLORS } from '../../Colors'

type MenuProps = {
  negative: boolean
  theme: any
}

type LogoContainerProps = {
  emptyLogo: boolean
}

export const DesktopMenuContainer = styled.div`
  display: none;
  background-color: white;

  @media (min-width: 992px) {
    width: -webkit-fill-available;
    height: 95px;
    display: flex;
    align-items: center;
    padding: 0 175px 0 80px;
    z-index: 1;
    box-shadow: ${(props: MenuProps) => props.negative && '0 6.4px 13.8px 0 rgb(0 0 0 / 14%) !important'};
  }
`

export const LogoContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    justify-content: left;
    align-items: center;
    width: ${(props: LogoContainerProps) => (props.emptyLogo ? '700px' : '215px')};
    height: 100px;
    margin-top: 15px;
  }
`

export const TitleLink = styled.a`
  text-decoration: none;
`

export const LogoImage = styled.img`
  @media (min-width: 992px) {
    max-width: 80%;
    max-height: 100px;
  }
`
export const DesktopMenuListContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    position: relative;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }
`

export const DesktopMenuList = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`

export const MenuItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    align-items: center;
  }
`

export const DrawerContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 5px 20px;
`

export const Divider = styled.div`
  margin-top: 20px;
  background-color: ${COLORS.DARK_LIGTH};
  width: 300px;
  height: 2px;
`

export const ButtonLogin = styled.a`
  @media (min-width: 992px) {
    margin-left: 60px;
  }
  white-space: nowrap;
  text-align: center;
  background-color: ${(props: MenuProps) => props.theme.color_main};
  display: flex;
  justify-content: center;
  margin: 50px 0;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  :hover {
    box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.24);
  }
  color: white;
`

// Menu Mobile

export const MobileMenuContainer = styled.div`
  padding: 22px 21px;
  position: absolute;
  width: -webkit-fill-available;
  display: flex;
  z-index: 3;
  box-shadow: ${(props: MenuProps) => props.negative && '0 4px 22px 0 rgba(0, 0, 0, 0.05)'};
  background-color: white;
  justify-content: space-between;

  @media (min-width: 992px) {
    display: none;
  }
`

export const MobileExpandIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ExpandIcon = styled.img`
  width: 17px;
`

export const MobileLogoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MobileLogoImage = styled.img`
  width: 59.4px;
  margin-left: -17px;
`

export const DrawerContainer = styled.div`
  background-color: white;
  p {
    margin: 0;
  }
`

export const BackgroundDrawerContainer = styled.div`
  background-color: rgba(50, 50, 50, 0.8);
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: scroll;
`

export const MobileMenuBrandLine = styled.div`
  width: 100%;
  height: 32px;
  z-index: 100000;
  position: absolute;
  background: ${({ theme }: any) => theme.color_main};
  bottom: 0;
  left: 0;
`

export const CloseIconContainer = styled.div`
  width: 36px;
  height: 27px;
  padding: 50px 0 105px 46px;
`

export const DrawerLogoContainer = styled.div`
  margin-top: 80px;
`

export const DrawerLogoImage = styled.img`
  width: 100px;
`

export const MobileMenuDrawerList = styled.div``
