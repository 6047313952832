import styled from 'styled-components'
import { getLightenColor } from '../../../../helpers/lightenColor'

type Props = {
  selected: boolean
  theme: any
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: '#ffffff';
  box-shadow: 0 6.4px 13.8px 0 rgba(0, 0, 0, 0.14);
  border-radius: 12px;
`

export const CardWrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: row;
  cursor: pointer;
  background-color: ${({ selected, theme }: Props) => (selected ? getLightenColor(theme.color_main) : '#ffffff')};
  box-shadow: ${({ selected }: Props) => (selected ? 'none' : '0 6px 14px 0 rgba(0, 0, 0, 0.14)')};
  border-radius: 12px;
`

export const RadioButton = styled.svg`
  width: 17px;
  height: 17px;
  margin-right: 20px;
`

export const InfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 992px) {
    align-items: center;
    flex-direction: row;
  }
`

export const Title = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
`

export const SecurePayment = styled.div`
  display: flex;
  align-items: flex-end;

  p {
    margin: 0 0 0 8px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #737373;
    margin-top: 15px;

    @media (min-width: 992px) {
      margin-top: 0;
    }
  }
`

export const CashOption = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  cursor: pointer;
`
