import React from 'react'

import { Container, Banner, Title, Description, ActionButtons, Content } from './styles'

const Skeleton = () => (
  <Container>
    <Banner />
    <Content>
      <div>
        <Title />
        <Description />
        <Description />
      </div>
      <ActionButtons>
        <div />
        <div />
      </ActionButtons>
    </Content>
  </Container>
)

export default Skeleton
