import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from 'styled-components'

import { LinkRef, IconContainer, Title } from './styles'

type LinkButtonProps = {
  url: string
  label: string
  blank?: boolean
  hasIcon?: boolean
  small: boolean
}

const LinkButton = ({ url, label, blank, hasIcon, small }: LinkButtonProps) => {
  const themeContext = useContext(ThemeContext)

  return (
    <LinkRef to={url} target={blank ? '_blank' : undefined}>
      <Title small={small}>{label}</Title>
      {hasIcon && (
        <IconContainer>
          <FontAwesomeIcon color={themeContext.color_main} icon={faArrowRight} size={small ? 'xs' : 'sm'} />
        </IconContainer>
      )}
    </LinkRef>
  )
}

LinkButton.defaultProps = {
  blank: undefined,
  hasIcon: false,
  small: false,
}

LinkButton.propTypes = {
  blank: PropTypes.bool,
  hasIcon: PropTypes.bool,
  small: PropTypes.bool,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default LinkButton
