import styled from 'styled-components'

import { COLORS } from '../..'

type Props = {
  featured: boolean | undefined
}

export const Container = styled.div`
  padding: 65px 39px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  box-shadow: 0 15px 95px 0 rgba(49, 93, 146, 0.11);
  background-color: ${(props: Props) => (props.featured ? COLORS.MAIN_COLOR : 'white')};
  margin-bottom: 30px;

  @media (min-width: 992px) {
    margin-bottom: 0px;
  }
`

export const StartDateContainer = styled.div`
  margin-top: 18px;
`

export const AmountContainer = styled.div`
  margin-top: 45px;
`

export const ActionButtonContainer = styled.div`
  margin-top: 75px;
`
