import styled from 'styled-components'

type Props = {
  programScreen?: boolean
  theme: any
}

export const Container = styled.div`
  position: relative;
  background-size: cover !important;
  background-position: 50% !important;
  height: ${({ programScreen }: Props) => (programScreen ? '800px' : '600px')};
  padding-bottom: ${({ programScreen }: Props) => (programScreen ? '0px' : '32px')};

  @media (min-width: 992px) {
    height: 600px;
    align-items: flex-end;
    display: flex;
    padding-bottom: 0;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 0 60px 32px 60px;
  }

  @media (min-width: 992px) {
    padding: 0 0 32px 80px;
    width: 763px;
    height: auto;
  }
`

export const Card = styled.div`
  width: ${({ programScreen }: Props) => (programScreen ? '100vw' : '70vw')};
  max-width: 100%;
  padding: 40px 32px;
  border-radius: ${({ programScreen }: Props) => (programScreen ? '0' : '16px')};
  box-shadow: ${({ programScreen }: Props) => !programScreen && '0 2px 14px 0 rgba(32, 26, 36, 0.24)'};
  background-color: ${({ programScreen }: Props) => (programScreen ? 'white' : 'rgba(256, 256, 256, 0.8)')};
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    border-radius: 16px;
    box-shadow: 0 2px 14px 0 rgba(32, 26, 36, 0.24);
    margin-bottom: 32px;
  }
`

export const Title = styled.p`
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`
export const Description = styled.p`
  padding-top: 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`

export const MainButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-self: center;
  @media (min-width: 768px) {
    width: fit-content;
    display: block;
  }
`

export const PrimaryButton = styled.div`
  width: 100%;
  height: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background-color: ${({ theme }: Props) => theme.color_main};

  @media (min-width: 768px) {
    width: fit-content;
    padding: 0 24px;
  }

  p {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
`

export const WhatsappButton = styled.div`
  margin-top: 10px;

  @media (min-width: 768px) {
    margin: 0 0 0 15px;
  }
`

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

export const Divider = styled.div`
  margin-top: 36px;
  width: 80vw;
  height: 1px;
  align-self: center;
  @media (max-width: 768px) {
    border-bottom: ${({ programScreen }: Props) => programScreen && '1px solid #d9d9d9'};
  }
`
