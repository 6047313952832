import React from 'react'
import PropTypes from 'prop-types'

import { BodyTitle, BodyText } from '../..'
import { Container, BannerImage, ContentContainer, CustomerInfoContainer, CustomerAvatar, Testimony } from './styles'

type CardTestimonyProps = {
  bannerUrl: string
  customerAvatarUrl: string
  customerName: string
  title: string
  description: string
}

export const CardTestimony = ({
  bannerUrl,
  customerAvatarUrl,
  customerName,
  title,
  description,
}: CardTestimonyProps) => {
  const bannerImage = {
    background: `url(${bannerUrl}) center center no-repeat`,
  }
  const customerAvatar = {
    background: `url(${customerAvatarUrl}) center center no-repeat`,
  }

  return (
    <Container>
      <BannerImage style={bannerImage} />
      <ContentContainer>
        <Testimony>
          <BodyTitle>{title}</BodyTitle>
          <BodyText>{description}</BodyText>
        </Testimony>
        <CustomerInfoContainer>
          <CustomerAvatar style={customerAvatar} />
          <div>
            <BodyTitle>{customerName}</BodyTitle>
          </div>
        </CustomerInfoContainer>
      </ContentContainer>
    </Container>
  )
}

CardTestimony.propTypes = {
  bannerUrl: PropTypes.string.isRequired,
  customerAvatarUrl: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default CardTestimony
