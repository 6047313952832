import styled from 'styled-components'

type Props = {
  theme: any
}

export const OverlayBackground = styled.div`
  display: block;
  position: fixed;
  z-index: 1024;
  width: 100%;
  height: 100%;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: scroll;

  p {
    margin: 0;
  }
`

export const Overlay = styled.form`
  background-color: white;
  padding: 31px 30px 28px 33px;
  margin: 5% auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  max-width: 385px;
`

export const CrossIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  cursor: pointer;
`

export const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: left;

  color: #000000;

  width: 268px;

  padding: 13px 0 22px 0;
`

export const FormInputContainer = styled.div`
  margin-bottom: 16px;
`

export const NameInputContainer = styled.div`
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin: 0 7px 0 0;
  }
`

export const InputWrapper = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`

export const SubmitButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
