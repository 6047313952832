import styled from 'styled-components'

export const LinkReset = styled.a`
  text-decoration: none;
  color: unset;
`

export const Container = styled.div`
  padding: 18px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  box-shadow: 0 5px 19px 0 rgba(100, 103, 173, 0.13);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 9px;
  overflow-wrap: break-word;
  word-break: break-word;

  @media (min-width: 992px) {
    width: 196px;
    margin-left: 0;
    margin-right: 14px;
  }

  @media (min-width: 768px) {
    width: 320px;
  }
`

export const SocialMediaIcon = styled.img`
  width: 25px;
  height: 25px;
  position: relative;
  top: 15px;
`

export const SocialMediaUsername = styled.div`
  width: 72px;
  height: 35px;
  font-family: 'ProductSansBold', sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 1px;
  color: #474a4f;
  position: relative;
  top: 15px;
  left: 35px;
`
