import React from 'react'
import PropTypes from 'prop-types'

import { Container, InputContainer, Label, Input, Overlay, ErrorContainer, ErrorLabel } from './styles'
import { isEmpty } from 'lodash'

type Props = {
  placeholder?: string
  value?: string
  onChangeValue?: (e: any) => void
  onBlur?: () => void
  label?: string
  error?: boolean
  disabled?: boolean
  errorMessage?: string
  register?: any
  id?: string
  name?: string
  type?: string
  useAcademyColor?: boolean
}

const StandarInput = ({
  error = false,
  disabled = false,
  useAcademyColor,
  label = '',
  errorMessage = '',
  placeholder = '',
  value,
  onChangeValue,
  register,
  onBlur,
  ...props
}: Props) => {
  return (
    <Container>
      <InputContainer error={error} disabled={disabled} useAcademyColor={useAcademyColor}>
        {disabled && <Overlay />}
        {!isEmpty(label) && <Label>{label}</Label>}
        <Input
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChangeValue}
          ref={register}
          {...props}
        />
      </InputContainer>
      {error && (
        <ErrorContainer>
          <img src={require('./images/cross-invalid.svg')} />
          <ErrorLabel>{errorMessage}</ErrorLabel>
        </ErrorContainer>
      )}
    </Container>
  )
}

StandarInput.defaultProps = {
  placeholder: '',
  error: false,
  disabled: false,
  errorMessage: '',
  id: '',
  name: '',
  type: '',
  label: '',
}

StandarInput.propTypes = {
  onChangeValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  register: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
}

export default StandarInput
