import styled, { css } from 'styled-components'

import { COLORS } from '../../Colors'

type Props = {
  negative: boolean
  disabled: boolean
  theme: any
}

//TODO: reseatch about better styled components code styling.
//TODO: negative should be another component?
export const StyledMainButton = styled.button`
  ${(props: Props) =>
    props.negative
      ? css`
          width: 254px;
          border: 1px solid #e5e5e5;
          box-sizing: border-box;
        `
      : css`
          border: none;
        `};
  min-height: 33px;
  overflow: hidden;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  padding: 8px 24px;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: white;
  background-color: ${(props: Props) => (props.negative ? 'transparent' : '#646363')};
  background-color: ${({ disabled }: Props) => disabled && COLORS.DARK_LIGTH};
`

export const TextButton = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  color: #ffffff;
`

export default StyledMainButton
