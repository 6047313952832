import styled from 'styled-components'
import { isUndefined } from 'lodash'

type Props = {
  theme: any
  error: boolean
  disabled: boolean
  useAcademyColor?: boolean
}

export const Container = styled.div`
width: 100%;

p { 
  margin: 0;
}
`

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  border: 1.5px solid ${(props: Props) => (props.error ? '#EE9B7F' : '#D9D9D9')};
  border: ${(props: Props) => props.disabled && '#d9d9d9'};
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;

  padding: 10px 16px;

  &:focus-within {
    border: 1.5px solid ${(props: Props) => (isUndefined(props.useAcademyColor) ? '#953bf7' : props.theme.color_main)};
  }
`

export const Overlay = styled.div`
  position: absolute;
  background: rgb(240, 240, 240, 0.6);
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;

  border: 1.5px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 10px;
`

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #52575c;

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;

  padding-bottom: 8px;
`

export const Input = styled.input`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border: none;

  width: 100%;

  color: #241d29;

  flex: none;
  order: 1;
  align-self: flex-start;
  flex-grow: 0;

  &:focus {
    outline: none;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
`

export const ErrorLabel = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #ee9b7f;

  padding-left: 6px;
`
