import styled, { css } from 'styled-components'
import { isUndefined } from 'lodash'

type Props = {
  theme: any
  disabled: boolean
  highlightBorder: boolean
  small: boolean
  useAcademyColor?: boolean
}

type DropdownItemProps = {
  highlightItemSelected: boolean
}

export const Container = styled.div`
width: 100%;

p { 
  margin: 0;
}
`

export const InputContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;

  padding-left: 16px;
  height: ${(props: Props) => (props.small ? '39px' : '66px')};
  z-index: 1;
  background: white;

  ${(props: Props) => {
    if (props.highlightBorder) {
      return css`
        border: 1.5px solid
          ${(props: Props) => (isUndefined(props.useAcademyColor) ? '#953bf7' : props.theme.color_main)};
      `
    }

    if (props.disabled) {
      return css`
        border: 0 solid #d9d9d9;
      `
    }

    return css`
      border: 1.5px solid #d9d9d9;
    `
  }};
  box-sizing: border-box;
  border-radius: 10px;
`

export const Overlay = styled.div`
  position: absolute;
  background: rgb(240, 240, 240, 0.6);
  height: 100%;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;

  border: 1.5px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 10px;
`

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #52575c;

  flex: none;
  order: 0;
  align-self: flex-start;
  flex-grow: 0;

  padding-bottom: 8px;
`

export const Input = styled.input`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  border: none;

  width: 100%;

  color: #979797;

  background: none;

  flex: none;
  order: 1;
  align-self: flex-start;
  flex-grow: 0;

  &:focus {
    outline: none;
  }
`

export const ArrowImage = styled.div`
  position: absolute;
  right: 18px;
`

export const DropdownListContainer = styled.div`
  z-index: 0;
  border: 1.5px solid #e5e5e5;
  box-sizing: border-box;

  max-height: 250px;
  overflow: hidden;
  overflow-y: scroll;

  box-shadow: 0px 3.10251px 12.7675px rgba(0, 0, 0, 0.08);
  padding: 10px 0 10px 0;
  margin-top: -10px;
  border-radius: 0 0 10px 10px;
`

export const DropdownItemContainer = styled.div`
  background: ${(props: DropdownItemProps) => props.highlightItemSelected && ''};
`

export const DropdownItemLabelContainer = styled.div`
  padding: 22px 0 18px 18px;
`

export const DropdownItem = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: ${(props: DropdownItemProps) => (props.highlightItemSelected ? '#953BF7 ' : '#979797')};
`

export const SeparatorLine = styled.div`
  border: 0.75px solid #e5e5e5;
`
