import styled from 'styled-components'

type Props = {
  theme: any
}

export const LinkRef = styled.a`
  text-decoration: none;
  color: unset;
`

export const MenuItemContainer = styled.div`
  @media (min-width: 992px) {
    margin-left: 60px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

export const ExpandIconContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    justify-content: center;
    float: right;
    width: 12px;
    margin-left: 10px;
  }
`

export const ExpandIcon = styled.img`
  width: 100%;
`

export const MenuDropdownContainer = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    overflow: visible;
    background-color: white;
    width: 232px;
    height: 312px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px 0px 20px rgba(0, 0, 0, 0.1);
  }

  p {
    margin: 0;
  }
`

export const SubItemContainer = styled.div`
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;

    &:hover {
      background-color: #eeeeee;
    }
  }
`

export const TypeName = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;

  color: #52575c;

  max-width: 145px;
`

export const DropdownContainer = styled.p`
  @media (min-width: 992px) {
    z-index: 2;
    margin-top: -5px;
    position: absolute;
  }
`

export const ProgramListContainer = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    display: flex;

    height: 312px;
    top: 0;
    left: 0;

    overflow: hidden;

    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;

    z-index: -2000;
    background: white;

    margin-left: 116px;

    p {
      margin: 0;
    }
  }
`

export const ProgramList = styled.div`
  @media (min-width: 992px) {
    width: 100%;
    padding: 30px 7px 0 0;
    height: 230px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`

export const ProgramItem = styled.div`
  @media (min-width: 992px) {
    padding: 0 25px 0 160px;
  }
  cursor: pointer;
`

export const ProgramNameContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
`

export const ProgramName = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  align-items: center;

  color: #52575c;

  width: 127px;

  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: ${({ theme }: Props) => theme.color_main};
  }
`

export const Divider = styled.div`
  width: 120px;
  height: 0px;

  border: 0.5px solid rgba(0, 0, 0, 0.5);

  margin: 15px 0;
`

export const ActionButtonContainer = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    bottom: 27px;
    left: 160px;

    cursor: pointer;
  }
`

export const SeeAllTypeActionButtonContainer = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    bottom: 27px;
    left: 28px;

    cursor: pointer;
  }
`

export const SeeAllTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: ${({ theme }: Props) => theme.color_main};
`
