import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { ThemeContext } from 'styled-components'

import { MainButton, COLORS } from '../../kit'
import {
  Container,
  Content,
  Card,
  Title,
  Description,
  MainButtonContainer,
  PrimaryButton,
  WhatsappButton,
  ButtonsContainer,
  Divider,
} from './styles'

type PortraitTypes = {
  programScreen?: boolean
  portraitUrl: string
  title?: string
  description?: string
  mainButtonLabel?: string
  secondaryButtonLabel?: string
  onClickMainButton?: () => void
  onClickSecondaryButton?: () => void
}

const Portrait = ({
  programScreen,
  portraitUrl,
  title,
  description,
  mainButtonLabel,
  onClickMainButton,
  secondaryButtonLabel,
  onClickSecondaryButton,
}: PortraitTypes) => {
  const themeContext = useContext(ThemeContext)

  const background = () => {
    let backgroundColor: string = COLORS.GRAY_03
    const { color_main: mainColor } = themeContext
    const hasPortrait = !isNil(portraitUrl)

    if (hasPortrait) {
      backgroundColor = `url(${portraitUrl}) no-repeat`
    }

    if (!hasPortrait && mainColor !== COLORS.WHITE) {
      backgroundColor = `${themeContext.color_main}`
    }

    return { background: backgroundColor }
  }

  return (
    <Container style={background()} programScreen={programScreen}>
      <Content>
        <Card programScreen={programScreen}>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ButtonsContainer>
            {!isNil(onClickMainButton) && !isNil(mainButtonLabel) && (
              <MainButtonContainer>
                <PrimaryButton onClick={onClickMainButton}>
                  <p>{mainButtonLabel}</p>
                </PrimaryButton>
              </MainButtonContainer>
            )}
            {!isNil(onClickSecondaryButton) && !isNil(secondaryButtonLabel) && (
              <WhatsappButton>
                <MainButton label={secondaryButtonLabel} onClick={onClickSecondaryButton} />
              </WhatsappButton>
            )}
          </ButtonsContainer>
          <Divider programScreen={programScreen} />
        </Card>
      </Content>
    </Container>
  )
}

Portrait.defaultProps = {
  portraitUrl: '',
  mainButtonLabel: undefined,
  secondaryButtonLabel: undefined,
  onClickSecondaryButton: undefined,
  onClickMainButton: undefined,
}

Portrait.propTypes = {
  portraitUrl: PropTypes.string,
  mainButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  onClickSecondaryButton: PropTypes.string,
  onClickMainButton: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Portrait
