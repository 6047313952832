import axios from '../../utils/axios'
import { normalizeProgramMetaData } from './normalize-data'

export const getProgramData = async (academyId: number, programSlug: string) =>
  await axios.get(`/academies/${academyId}/programs/${programSlug}`)

export const getEdition = async (academyId: number, editionId: string, priceId?: string) =>
  await axios.get(`/academies/${academyId}/editions/${editionId}` + (priceId ? '?priceId=' + priceId : ''))

export const getPrograms = async (academyId: number) => await axios.get(`/academies/${academyId}/programs`)

export const getProgramPdf = async (academyId: number, programId: string, payload: any) =>
  await axios.post(`/academies/${academyId}/programs/${programId}/download`, payload)

export const getProgramMeta = async (academyId: number) => {
  const { data } = await axios.get(`/academies/${academyId}/program-meta`)

  //TODO: workaround till we implement mobx/redux
  return normalizeProgramMetaData(data)
}

export const getProgramsWithType = async (academyId: number) =>
  await axios.get(`/academies/${academyId}/programs/types`)
