/* eslint-disable @typescript-eslint/camelcase */
import { isNil } from 'lodash'

export const normalizeProgramMetaData = (programMeta: any) => {
  const {
    contents_title,
    contents_subtitle,
    benefits_title,
    editions_title,
    editions_subtitle,
    facilitators_title,
    facilitators_subtitle,
    testimonies_title,
    testimonies_subtitle,
    partners_title,
    partners_subtitle,
    faqs_title,
    faqs_subtitle,
  } = programMeta

  const normalizedData = {
    contentsTitle: isNil(contents_title) ? 'Contenido' : contents_title,
    contentsSubtitle: isNil(contents_subtitle) ? null : contents_subtitle,
    benefitsTilte: isNil(benefits_title) ? 'Beneficios' : benefits_title,
    editionsTitle: isNil(editions_title) ? 'Inscríbete al programa' : editions_title,
    editionsSubtitle: isNil(editions_subtitle) ? null : editions_subtitle,
    facilitatorsTitle: isNil(facilitators_title) ? 'Nuestros instructores' : facilitators_title,
    facilitatorsSubtitle: isNil(facilitators_subtitle) ? null : facilitators_subtitle,
    testimoniesTitle: isNil(testimonies_title) ? 'Nuestros graduados' : testimonies_title,
    testimoniesSubtitle: isNil(testimonies_subtitle) ? 'Conozca qué dicen de nosotros' : testimonies_subtitle,
    partnersTitle: isNil(partners_title) ? 'Partners del programa' : partners_title,
    partnersSubtitle: isNil(partners_subtitle) ? null : partners_subtitle,
    faqsTitle: isNil(faqs_title) ? 'Preguntas frecuentes' : faqs_title,
    faqsSubtitle: isNil(faqs_subtitle) ? null : faqs_subtitle,
  }

  return normalizedData
}
