import React from 'react'
import { isEmpty, isEqual, isNil } from 'lodash'
import { useTranslation } from 'react-i18next'

import { handleGoToStudentDashboard } from '../../../helpers/studentDashboard'
import { MainMenu } from '../../../components/kit'
import useGlobalContext from '../../../store/GlobalContext/useGlobalContext'
import {
  Layout,
  Container,
  Summary,
  Content,
  Title,
  CustomerDetailsDescription,
  ProgramTitle,
  SummaryTitle,
  LmsContainer,
  StudentDashboardActionButton,
  DescriptionText,
  ConfettiIcon,
  ButtonWrapper,
  DataAndButton,
} from './styles'
import CheckoutType from '../../../enums/CheckoutType'

const CONFETTI_ICON = require('./images/confetti-icon.png')

type Props = {
  history: any
  location: any
}

const PaymentSuccess = ({ location }: Props) => {
  const { t } = useTranslation()

  const { edutiveLmsEnabled } = useGlobalContext()

  const {
    state: {
      academyDomain,
      lmsActive,
      programTitle,
      edition,
      customerEmail,
      checkoutType,
      isDesktop,
      mpStatus,
    },
  } = location

  const isTransfer = isEqual(checkoutType, t('paymentCheckout.payWithTransfer'))
  const isPendingTransaction = !isNil(mpStatus)
  const activeLms = edutiveLmsEnabled && lmsActive

  const renderDate = () => {
    if (edition) {
      if (!isEmpty(edition.start_date) && !isEmpty(edition.end_date)) {
        return t('paymentSuccess.editionDate', { startDate: edition.start_date, endDate: edition.end_date })
      } else if (!isEmpty(edition.end_date)) {
        return t('paymentSuccess.onlyEndDate', { endDate: edition.end_date })
      } else if (!isEmpty(edition.start_date)) {
        return t('paymentSuccess.onlyStartDate', { startDate: edition.start_date })
      }
    }
  }

  return (
    <Layout>
      <MainMenu negative showDesktopMenu={false} />
      <Container isDesktop={isDesktop}>
        <Content>
          <ConfettiIcon src={CONFETTI_ICON} />

          {!isTransfer && (
            <Title>{!isPendingTransaction ? t('paymentSuccess.title') : t('paymentSuccess.pendingTitle')}</Title>
          )}

          {isTransfer && (
            <>
              <CustomerDetailsDescription>{t('paymentSuccess.transferDashboardInfo')}</CustomerDetailsDescription>
              <CustomerDetailsDescription>
                {t('paymentSuccess.transferDashboardInfoSteps', { email: customerEmail })}
              </CustomerDetailsDescription>
              <CustomerDetailsDescription>
                {t('paymentSuccess.transferDashboardInfoCheckEmail')}
              </CustomerDetailsDescription>
            </>
          )}

          {isPendingTransaction && (
            <CustomerDetailsDescription>{t('paymentSuccess.pendingDescription')}</CustomerDetailsDescription>
          )}

          {!!activeLms && !isTransfer && !isPendingTransaction && (
            <LmsContainer>
              <CustomerDetailsDescription>{t('paymentSuccess.studentDashboardInfo')}</CustomerDetailsDescription>
              <CustomerDetailsDescription>
                {t('paymentSuccess.studentDashboardImportantInfo', { email: customerEmail })}
              </CustomerDetailsDescription>
            </LmsContainer>
          )}
          {!activeLms && !isTransfer && !isPendingTransaction && (
            <CustomerDetailsDescription>
              {t('paymentSuccess.confirmDashboardInfo', { email: customerEmail })}
            </CustomerDetailsDescription>
          )}
        </Content>
        <DataAndButton isDesktop={isDesktop}>
          <Summary>
            <SummaryTitle>{t('paymentSuccess.summaryTitle')}</SummaryTitle>
            <ProgramTitle>{programTitle}</ProgramTitle>
            <DescriptionText>{renderDate()}</DescriptionText>
            <DescriptionText>{edition?.date_details}</DescriptionText>
          </Summary>
          {!isTransfer && !!activeLms && !isPendingTransaction && (
            <ButtonWrapper isDesktop={isDesktop}>
              <StudentDashboardActionButton onClick={() => handleGoToStudentDashboard(academyDomain)}>
                {t('paymentSuccess.goToStudentDashboardButtonLabel')}
              </StudentDashboardActionButton>
            </ButtonWrapper>
          )}
        </DataAndButton>
      </Container>
    </Layout>
  )
}

export default PaymentSuccess
