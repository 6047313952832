import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'

type Props = {
  theme: any
  small: boolean
}

export const LinkRef = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`

export const Title = styled.p`
  ${(props: Props) =>
    props.small
      ? css`
          font-size: 11.8916px;
          line-height: 14px;
        `
      : css`
          font-size: 16px;
          line-height: 17px;
        `};
  color: ${(props: Props) => props.theme.color_main};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 1px;
`

export default LinkRef
