import React from 'react'
import { useTranslation } from 'react-i18next'

import { Container, ActionContainer, DescriptionContainer, Title, Description } from './styles'
import { MainLayout } from '../../components/common'

const AcademyNotFound = () => {
  const { t } = useTranslation()

  return (
    <MainLayout>
      <Container>
        <Title>{t('routeNotFound.errorExclamation')}</Title>
        <DescriptionContainer>
          <Title>{t('routeNotFound.title')}</Title>
        </DescriptionContainer>
        <ActionContainer>
          <Description>{t('routeNotFound.description')}</Description>
        </ActionContainer>
      </Container>
    </MainLayout>
  )
}

export default AcademyNotFound
