import React, { useState, useEffect, useCallback, memo, useContext } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { isEmpty, isEqual, isNil } from 'lodash'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  DesktopMenuContainer,
  LogoContainer,
  LogoImage,
  DesktopMenuListContainer,
  DesktopMenuList,
  ButtonLogin,
  MobileMenuContainer,
  MobileExpandIconContainer,
  ExpandIcon,
  MobileLogoImageContainer,
  MobileLogoImage,
  DrawerContainer,
  MobileMenuDrawerList,
  TitleLink,
  MenuItemsWrapper,
  Divider,
  DrawerContainerHeader,
  BackgroundDrawerContainer,
} from './styles'
import { getProgramsWithType, FacilitatorsType, getFacilitators } from '../../../../api'
import { getFormattedUrl } from '../../../../helpers/url-formatter'
import DropdownMenu from '../DropdownMenu'
import DrawerDropdownMenu from '../DrawerDropdownMenu'
import IconChallenges from './images/icon-challenges.png'

import useGlobalContext from '../../../../store/GlobalContext/useGlobalContext'
import { DynamicHero, DynamicMenuMobileItemTitle } from '../../Typography'

type MainMenuProps = {
  negative: boolean
  showDesktopMenu: boolean
}

const ACADEMY_NAME_MAX_LENGTH = 25
const ACADEMY_NAME_BIGGEST_LENGTH = 50
const REGULAR_ACADEMY_NAME_SIZE = 47
const MIN_SIZE_NAME = 25
const SIZE_SUBSTRACT_FOR_EXTRA_CHAR = 0.5

const ACADEMY_NAME_MOBILE_MAX_LENGTH = 30
const MOBILE_ACADEMY_NAME_SIZE = 20
const MIN_MOBILE_SIZE_NAME = 12

const CLOSE_MENU_ICON = require('./images/close-menu-mobile.svg')

const MainMenu = ({ negative, showDesktopMenu = true }: MainMenuProps) => {
  const themeContext = useContext(ThemeContext)
  const { websiteEnabled } = useGlobalContext()
  const [showItemIndex, setShowItemIndex] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [programs, setPrograms] = useState<any | undefined>(undefined)
  const [facilitators, setFacilitators] = useState<FacilitatorsType | undefined>(undefined)
  const [menu, setMenu] = useState<any>([])
  const [academyNameSize, setAcademyNameSize] = useState(0)
  const [academyNameMobileSize, setAcademyNameMobileSize] = useState(0)
  const [academyName, setAcademyName] = useState('')
  const { t } = useTranslation()

  const { academyId, customMenuItemsJson } = useGlobalContext()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: programData } = await getProgramsWithType(academyId)
        const { data: facilitatorsData } = await getFacilitators(academyId)
        const customMenuItems = JSON.parse(customMenuItemsJson)
        const menuItemsWithout: any = []
        programData.map((item: any) => {
          if (!isEmpty(item.programs)) {
            menuItemsWithout.push(item)
          }
        })
        customMenuItems.loginItem && customMenuItems.headerItems.push(customMenuItems.loginItem)
        customMenuItems.headerItems.map((item: any) => {
          if (item.is_visible) {
            if (isEqual(item.url, '/products')) {
              menu.push({
                title: item.title,
                icon: IconChallenges,
                blank: false,
                subItems: menuItemsWithout,
                url: '/products',
              })

              return
            }

            if (isEqual(item.url, '/instructors')) {
              menu.push({
                subItems: undefined,
                icon: '',
                title: item.title,
                blank: false,
                url: '/instructors',
              })

              return
            }

            const url = getFormattedUrl(item.url)
            menu.push({ title: item.title, type: item.type, url: url, icon: '', blank: true, subItems: undefined })
          }
        })
        setPrograms(menuItemsWithout)
        setFacilitators(facilitatorsData)
      } catch (e) {
        // TODO: what message should I show in case of error
      }
    }

    const calculateSize = () => {
      let finalSize = REGULAR_ACADEMY_NAME_SIZE
      setAcademyName(themeContext.name)

      if (themeContext.name.length > ACADEMY_NAME_MAX_LENGTH) {
        if (themeContext.name.length > ACADEMY_NAME_BIGGEST_LENGTH) {
          setAcademyName(`${themeContext.name.slice(0, ACADEMY_NAME_BIGGEST_LENGTH - 3)}...`)
          finalSize = MIN_SIZE_NAME
        } else {
          finalSize =
            REGULAR_ACADEMY_NAME_SIZE -
            (themeContext.name.length - ACADEMY_NAME_MAX_LENGTH + SIZE_SUBSTRACT_FOR_EXTRA_CHAR)
        }
      }

      return finalSize
    }

    const calculateMobileSize = () => {
      let finalSize = MOBILE_ACADEMY_NAME_SIZE
      setAcademyName(themeContext.name)

      if (themeContext.name.length > ACADEMY_NAME_MOBILE_MAX_LENGTH) {
        if (themeContext.name.length > ACADEMY_NAME_BIGGEST_LENGTH) {
          setAcademyName(`${themeContext.name.slice(0, ACADEMY_NAME_BIGGEST_LENGTH - 3)}...`)
          finalSize = MIN_MOBILE_SIZE_NAME
        } else {
          finalSize =
            MOBILE_ACADEMY_NAME_SIZE -
            (themeContext.name.length - ACADEMY_NAME_MOBILE_MAX_LENGTH) * SIZE_SUBSTRACT_FOR_EXTRA_CHAR
        }
      }

      return finalSize
    }

    setAcademyNameSize(calculateSize())
    setAcademyNameMobileSize(calculateMobileSize())
    fetchData()
  }, [])

  const toggleItemDropdown = (itemIndex: any): any => {
    if (isEqual(itemIndex, showItemIndex)) {
      setShowItemIndex(null)

      return
    }

    setShowItemIndex(itemIndex)
  }

  const handleShowDrawerButtonClick = useCallback(() => {
    setShowDrawer(true)
  }, [])

  const handleCloseDrawerButtonClick = useCallback(() => {
    setShowDrawer(false)
  }, [])

  const getAcademyLogo = (mobile = false) => {
    if (isNil(themeContext.logo_alt)) {
      return mobile ? (
        <DynamicMenuMobileItemTitle sizeText={academyNameMobileSize}>{academyName}</DynamicMenuMobileItemTitle>
      ) : (
        <DynamicHero sizeText={academyNameSize}>{academyName}</DynamicHero>
      )
    }
    return mobile ? <MobileLogoImage src={themeContext.logo_alt} /> : <LogoImage src={themeContext.logo_alt} />
  }

  return (
    <div>
      <DesktopMenuContainer negative={negative}>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <LogoContainer emptyLogo={isNil(themeContext.logo_alt)}>
              <TitleLink {...(!!websiteEnabled ? { href: '/' } : {})}>{getAcademyLogo()}</TitleLink>
            </LogoContainer>
          </Grid>
          <Grid item xs={9}>
            <DesktopMenuListContainer>
              <DesktopMenuList>
                {!isEmpty(menu) &&
                  showDesktopMenu &&
                  menu.map((item: any, i: any) => {
                    // TODO: workaround due to DOMString localStorage. Change it when we implement mobx or reactcontext
                    if (!isEqual(item.url, 'null')) {
                      return (
                        <MenuItemsWrapper
                          onMouseEnter={() => toggleItemDropdown(i)}
                          onMouseLeave={() => toggleItemDropdown(i)}
                        >
                          {item.type == 'LOGIN' ? (
                            <ButtonLogin negative={true} href={item.url} target="_blank" rel="noreferrer">
                              {item.title}
                            </ButtonLogin>
                          ) : (
                            <DropdownMenu
                              title={item.title}
                              showDropdown={isEqual(showItemIndex, i)}
                              subItems={item.subItems}
                              url={item.url}
                              blank={item.blank}
                              negative={negative}
                              key={i}
                            />
                          )}
                        </MenuItemsWrapper>
                      )
                    }
                  })}
              </DesktopMenuList>
            </DesktopMenuListContainer>
          </Grid>
        </Grid>
      </DesktopMenuContainer>
      <MobileMenuContainer negative={negative}>
        <MobileLogoImageContainer>
          <TitleLink {...(!!websiteEnabled ? { href: '/' } : {})}>{getAcademyLogo(true)}</TitleLink>
        </MobileLogoImageContainer>
        {showDesktopMenu && (
          <>
            <MobileExpandIconContainer onClick={handleShowDrawerButtonClick}>
              <ExpandIcon src={require('./images/toggle-menu-mobile-negative.png')} />
            </MobileExpandIconContainer>
            {showDrawer && (
              <BackgroundDrawerContainer>
                <DrawerContainer>
                  <DrawerContainerHeader>
                    <TitleLink href="/">{getAcademyLogo(true)}</TitleLink>
                    <img src={CLOSE_MENU_ICON} onClick={handleCloseDrawerButtonClick} />
                  </DrawerContainerHeader>
                  <MobileMenuDrawerList>
                    {!isEmpty(menu) &&
                      menu.map((item: any, i: any) => {
                        // TODO: workaround due to DOMString localStorage. Change it when we implement mobx or reactcontext
                        if (!isEqual(item.url, 'null')) {
                          return (
                            <MenuItemsWrapper>
                              {item.type === 'LOGIN' ? (
                                <div>
                                  <Divider />
                                  <ButtonLogin negative={true}>
                                    <a href={item.url} target="_blank" rel="noreferrer">
                                      {item.title}
                                    </a>
                                  </ButtonLogin>
                                </div>
                              ) : (
                                <DrawerDropdownMenu
                                  title={item.title}
                                  icon={item.icon}
                                  showDropdown={isEqual(showItemIndex, i)}
                                  subItems={item.subItems}
                                  itemIndex={i}
                                  toggleDropdown={toggleItemDropdown}
                                  url={item.url}
                                  blank={item.blank}
                                  key={i}
                                  onDrawerClose={handleCloseDrawerButtonClick}
                                />
                              )}
                            </MenuItemsWrapper>
                          )
                        }
                      })}
                  </MobileMenuDrawerList>
                </DrawerContainer>
              </BackgroundDrawerContainer>
            )}
          </>
        )}
      </MobileMenuContainer>
    </div>
  )
}

MainMenu.propTypes = {
  negative: PropTypes.bool,
  showDesktopMenu: PropTypes.bool,
}

MainMenu.defaultProps = {
  negative: false,
  showDesktopMenu: true,
}

export default memo(MainMenu)
