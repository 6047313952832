import styled from 'styled-components'

export const Container = styled.div`
  width: 384px;
  height: 202px;
  background: #eeeeee;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  box-shadow: 0px 6.43052px 13.7797px rgba(0, 0, 0, 0.14);

  p {
    margin: 0;
  }
`

export const ProgramInfo = styled.div`
  padding: 30px 39px 0 41px;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
  }
`

export const ProgramTitle = styled.p`
  max-width: 160px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`

export const Amount = styled.p`
  max-width: 160px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`

export const Month = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
`

export const Day = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  text-transform: uppercase;

  padding-top: 5px;
`

export const EventTypeContainer = styled.div`
  width: 93.37px;
  height: 28.19px;
  align-items: center;
  justify-content: center;
  display: flex;

  border: 0.92px solid #000000;
  box-sizing: border-box;
  border-radius: 6.5px;
  -webkit-border-radius: 6.5px;
  -moz-border-radius: 6.5px;

  margin: 19px 0 0 36px;
`

export const EventType = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
`
