import styled from 'styled-components'

type Props = {
  backgroundColor?: string
  primary?: boolean
  disabled: boolean
  theme?: any
}

export const Container = styled.button`
  width: 100%;
  padding: 8px 0;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  background-color: ${({ backgroundColor, primary }: Props) => (primary ? backgroundColor : 'white')};
  background-color: ${({ disabled }: Props) => disabled && 'gray'};
  border: ${({ primary, theme }: Props) => (primary ? 'none' : `1px solid ${theme.color_main}`)};
  color: ${({ primary, theme }: Props) => (primary ? 'white' : theme.color_main)};
  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  @media (min-width: 768px) {
    height: 32px;
  }
`
