import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil } from 'lodash'

import {
  DrawerMenuContainer,
  ItemListContainer,
  DropdownItemsContainer,
  DropdownItemTitleContainer,
  LinkRef,
  DrawerTitleContainer,
  ActionButtonContainer,
  SeeAllTitle,
} from './styles'
import { MenuMobileItemTitle, MobileMenuSubItemTitle } from '../../Typography'

type Props = {
  title: string
  icon: string
  subItems: any
  showDropdown: boolean
  toggleDropdown: (itemIndex: number) => void
  itemIndex: number
  url: string
  blank: boolean
  onDrawerClose: () => void
}

const DrawerMenuItem = ({
  title,
  icon,
  subItems,
  showDropdown,
  toggleDropdown,
  itemIndex,
  url,
  blank,
  onDrawerClose,
}: Props) => {
  //TODO: ask about this method and how it´s supposed to work
  const handleNavigation = (shouldCloseDrawer: boolean) => {
    if (shouldCloseDrawer) {
      window.open(url, '_self')
      onDrawerClose()
    }
  }

  const handleSeeAllButtonPress = () => {
    window.open('/products', '_self')
  }

  return (
    <DrawerMenuContainer>
      <DrawerTitleContainer onClick={() => handleNavigation(isEmpty(subItems))} clicked={showDropdown}>
        <ItemListContainer onClick={() => toggleDropdown(itemIndex)}>
          <div>
            <MenuMobileItemTitle>{title}</MenuMobileItemTitle>
          </div>
        </ItemListContainer>
      </DrawerTitleContainer>

      {showDropdown && !isEmpty(subItems) && (
        <DropdownItemsContainer>
          {!isEmpty(subItems) &&
            subItems.map((subItem: any) => {
              return (
                isNil(subItem.name) && (
                  <LinkRef
                    href={`/products/${subItem.type_id}`}
                    key={subItem.type_name}
                    onClick={() => handleNavigation(isEmpty(subItems))}
                  >
                    <DropdownItemTitleContainer key={subItem.type_name}>
                      <MobileMenuSubItemTitle key={subItem.type_name}>{subItem.type_name}</MobileMenuSubItemTitle>
                    </DropdownItemTitleContainer>
                  </LinkRef>
                )
              )
            })}
          <ActionButtonContainer onClick={handleSeeAllButtonPress}>
            <SeeAllTitle>VER TODOS</SeeAllTitle>
          </ActionButtonContainer>
        </DropdownItemsContainer>
      )}
    </DrawerMenuContainer>
  )
}

DrawerMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  subItems: PropTypes.array,
  showDropdown: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  itemIndex: PropTypes.number.isRequired,
  url: PropTypes.string,
  blank: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
}

DrawerMenuItem.defaultProps = {
  subItems: [],
  url: null,
}

export default DrawerMenuItem
