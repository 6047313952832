import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { FaqElementContainer, FaqAnswerContainer, FaqContainer, FaqQuestion, FaqQuestionIcon } from './styles'

type Props = {
  question: string
  answer: string
}

const FaqElement = ({ question, answer }: Props) => {
  const [opened, setOpened] = useState(false)

  const handleQuestionPress = () => {
    setOpened(!opened)
  }

  return (
    <FaqContainer>
      <FaqElementContainer onClick={handleQuestionPress}>
        <FaqQuestion>{question}</FaqQuestion>
        <FaqQuestionIcon>
          {opened ? (
            <img src={require('./images/expand-icon-down.png')} />
          ) : (
            <img src={require('./images/expand-icon-right.png')} />
          )}
        </FaqQuestionIcon>
      </FaqElementContainer>
      {opened && <FaqAnswerContainer>{answer}</FaqAnswerContainer>}
    </FaqContainer>
  )
}

FaqElement.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}

export default FaqElement
