import i18next from 'i18next'

const PaymentError = Object.freeze({
  CC_REJECTED_HIGH_RISK: 'cc_rejected_high_risk',
  CC_REJECTED_INSUFFICIENT_AMOUNT: 'cc_rejected_insufficient_amount',
  CC_REJECTED_OTHER_REASON: 'cc_rejected_other_reason',
  CC_REJECTED_CALL_FOR_AUTHORIZE: 'cc_rejected_call_for_authorize',
  CC_REJECTED_BAD_FILLED_SECURITY_CODE: 'cc_rejected_bad_filled_security_code',
  CC_REJECTED_BAD_FILLED_CARD_NUMBER: 'cc_rejected_bad_filled_card_number',
  CC_REJECTED_BAD_FILLED_DATE: 'cc_rejected_bad_filled_date',
  CC_REJECTED_MAX_ATTEMPTS: 'cc_rejected_max_attempts',
  INVALID_PAYMENT_METHOD: 'invalid_payment_method',
  CARD_ERROR: 'card_error',
})

const PaymentErrorMessage = Object.freeze({
  [PaymentError.CC_REJECTED_HIGH_RISK]: {
    title: i18next.t('paymentError.rejected'),
    description: i18next.t('paymentError.retry'),
    recommendation: i18next.t('paymentError.highRiskRecommendation'),
  },
  [PaymentError.CC_REJECTED_INSUFFICIENT_AMOUNT]: {
    title: i18next.t('paymentError.insufficientAmount'),
    description: i18next.t('paymentError.retryAnotherCard'),
    recommendation: i18next.t('paymentError.anotherMethodRecommendation'),
  },
  [PaymentError.CC_REJECTED_OTHER_REASON]: {
    title: i18next.t('paymentError.anotherReasonTitle'),
    description: i18next.t('paymentError.retry'),
    recommendation: i18next.t('paymentError.anotherReasonRecommendation'),
  },
  [PaymentError.CC_REJECTED_CALL_FOR_AUTHORIZE]: {
    title: i18next.t('paymentError.authorizeBankTitle'),
    description: '',
    recommendation: i18next.t('paymentError.anotherReasonRecommendation'),
  },
  [PaymentError.CC_REJECTED_BAD_FILLED_CARD_NUMBER]: {
    title: i18next.t('paymentError.badFilledTitle'),
    description: i18next.t('paymentError.badFilledDescription'),
    recommendation: i18next.t('paymentError.anotherReasonRecommendation'),
  },
  [PaymentError.CC_REJECTED_BAD_FILLED_SECURITY_CODE]: {
    title: i18next.t('paymentError.badFilledTitle'),
    description: i18next.t('paymentError.badFilledDescription'),
    recommendation: i18next.t('paymentError.anotherReasonRecommendation'),
  },
  [PaymentError.CC_REJECTED_BAD_FILLED_DATE]: {
    title: i18next.t('paymentError.badFilledTitle'),
    description: i18next.t('paymentError.badFilledDescription'),
    recommendation: i18next.t('paymentError.anotherReasonRecommendation'),
  },
  [PaymentError.CC_REJECTED_MAX_ATTEMPTS]: {
    title: i18next.t('paymentError.maxAttemptsTitle'),
    description: i18next.t('paymentError.badFilledDescription'),
    recommendation: i18next.t('paymentError.maxAttemptsRecommendation'),
  },
  [PaymentError.INVALID_PAYMENT_METHOD]: {
    title: i18next.t('paymentError.membershipError'),
    description: '',
    recommendation: i18next.t('paymentError.maxAttemptsRecommendation'),
  },
})

export default PaymentErrorMessage
