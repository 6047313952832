import styled from 'styled-components'

type Props = {
  theme: any
}

export const Container = styled.div`
  width: 90vw;

  background: white;

  box-shadow: 0px 2px 14px rgba(32, 26, 36, 0.24);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;

  p {
    margin: 0;
  }

  @media (min-width: 768px) {
    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 12px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 26px 59px 34px 37px;
  }
`

export const ProgramMonth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const Month = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #000000;
`

export const MonthDescription = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  max-width: 282px;

  margin-top: 7px;
`

export const ModalityLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120.37px;
  height: 28.19px;

  border: 0.918645px solid #000000;
  box-sizing: border-box;
  border-radius: 6.43052px;
  -webkit-border-radius: 6.43052px;
  -moz-border-radius: 6.43052px;

  margin-top: 42px;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    text-align: center;
  }
`

export const ProgramDate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`

export const ProgramCalendar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 41px;

  img {
    margin-right: 18px;
  }
`

export const ProgramHour = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    margin-right: 18px;
  }
`

export const CalendarEvent = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  color: #000000;
`

export const CalendarEventDescription = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

export const RecurrentEvent = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
  max-width: 150px;
`

export const RecurrentEventDescription = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`

export const Subscribe = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`

export const Quota = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #000000;

  padding-bottom: 64px;
`

export const SubscribeButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 171px;
  height: 36px;

  background: ${({ theme }: Props) => theme.color_main};
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;

  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  /* #FFFFFF */

  color: #ffffff;
`

export const Divider = styled.div`
  border: 1px solid #000000;
  margin: 10px 0 10px 0;
  @media (min-width: 768px) {
    margin: 0;
  }
`
