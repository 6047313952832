import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from 'styled-components'
import { isEmpty } from 'lodash'

import PaymentErrorMessage from '../../../enums/PaymentError'
import useGlobalContext from '../../../store/GlobalContext/useGlobalContext'
import { PaymentButton } from '../../../components/kit'
import * as S from './styles'

type Props = {
  apiStatus: string
  history: any
  onRetryButtonPress: () => void
}

type ErrorMessages = {
  title: string
  description: string
  recommendation: string
}

const ERROR_ICON = require('./images/alert-icon.png')

const PaymentError = ({ history, apiStatus, onRetryButtonPress }: Props) => {
  const { t } = useTranslation()
  const { websiteEnabled } = useGlobalContext()
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>({
    title: t('paymentError.defaultError'),
    description: t('paymentError.retry'),
    recommendation: '',
  })

  const themeContext = useContext(ThemeContext)
  const { color_main: mainColor } = themeContext

  const formatErrorMessages = () => {
    const objectMessage = PaymentErrorMessage[apiStatus]
    if (!isEmpty(apiStatus) && objectMessage) {
      setErrorMessages(objectMessage)
    } else if (!isEmpty(apiStatus)) {
      const defaultMessage = {
        title: apiStatus,
        description: t('paymentError.retry'),
        recommendation: '',
      }
      setErrorMessages(defaultMessage)
    }
  }

  useEffect(() => {
    formatErrorMessages()
  }, [apiStatus])

  return (
    <S.Container>
      <S.Wrapper>
        <img src={ERROR_ICON} />
        <S.Content>
          <div>
            <S.Title>{t('paymentError.title')}</S.Title>
            <S.ErrorDetail>{apiStatus?.toUpperCase}</S.ErrorDetail>
          </div>
          <div>
            <S.Details>
              <div>
                <S.DetailTitle>{t('paymentError.summaryTitle').toUpperCase()}</S.DetailTitle>
                <S.DetailDescription>{errorMessages.title}</S.DetailDescription>
                <S.Retry>{errorMessages.description}</S.Retry>
                <S.Tip>{errorMessages.recommendation}</S.Tip>
              </div>
            </S.Details>
            <S.ActionsButton>
              {!!websiteEnabled && (
                <S.RetryButton>
                  <PaymentButton
                    backgroundColor={mainColor}
                    label={t('paymentError.backToSite')}
                    onClick={() => history.goBack()}
                    primary={false}
                  />
                </S.RetryButton>
              )}
              <S.BackSiteButton>
                <PaymentButton
                  backgroundColor={mainColor}
                  label={t('paymentError.retryButton')}
                  onClick={onRetryButtonPress}
                />
              </S.BackSiteButton>
            </S.ActionsButton>
          </div>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  )
}

export default PaymentError
