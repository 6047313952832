import styled from 'styled-components'

type Props = {
  theme: any
}

export const Container = styled.div`
  width: 90vw;
  padding: 44px 0 48px 0;

  background: white;

  box-shadow: 0px 2px 14px rgba(32, 26, 36, 0.24);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0;
  }
  @media (min-width: 768px) {
    width: 384px;
  }
`

export const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;

  color: #000000;
`

export const Price = styled.div`
  margin-top: 35px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 36px;

  color: #000000;
`

export const Description = styled.div`
  margin-top: 13px;
  max-width: 274px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #241d29;
`

export const ActionButton = styled.button`
  margin-top: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 171px;
  height: 36px;

  border: ${({ theme }: Props) => `${theme.color_main} 2px solid`};
  background-color: transparent;
  border-radius: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  cursor: pointer;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: ${({ theme }: Props) => theme.color_main};
  }
`
