import { isEmpty, isUndefined } from 'lodash'

const getIsEdutiveSubdomain = (url: string) => {
  return url.includes('edutive.co')
}

const getSudomainName = (url: string) => {
  const splittedDomain = url.split('.')

  return splittedDomain[0].replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
}

const getUrlDomain = () => {
  const hostName = window.location.hostname

  return hostName.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
}

export const getAcademyDomain = (): { domain: string; isCustomDomain: boolean } => {
  const baseUrl = window.location.origin
  const isEdutiveSubdomain = getIsEdutiveSubdomain(baseUrl)

  if (process.env.NODE_ENV === 'development') {
    return {
      domain: 'dev-front',
      isCustomDomain: false,
    }
  }

  if (isEdutiveSubdomain) {
    const academySubdomain = getSudomainName(baseUrl)

    return {
      domain: academySubdomain,
      isCustomDomain: false,
    }
  }

  if (!isEdutiveSubdomain) {
    const academyDomain = getUrlDomain()

    return {
      domain: academyDomain,
      isCustomDomain: true,
    }
  }

  return {
    domain: '',
    isCustomDomain: false,
  }
}
