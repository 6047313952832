import { useState, useEffect } from 'react'

const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = () => {
    const width = isClient ? window.innerWidth : 992
    return {
      width,
      height: isClient ? window.innerHeight : 992,
      isMobile: width <= 768,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect((): any => {
    if (!isClient) {
      return false
    }

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
