import styled from 'styled-components'

import { getLightenColor } from '../../../helpers/lightenColor'

type Props = {
  theme: any
}

export const Container = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 60px;
    }
  }

  p {
    margin: 0;
  }
`

export const Wrapper = styled.div`
  padding: 10px 20px 0 20px;
  @media (min-width: 768px) {
    padding: 0;
  }
`

export const Content = styled.div`
  @media (min-width: 768px) {
    flex-direction: row;
    display: flex;
    margin-top: 10px;
  }
`

export const ActionsButton = styled.div`
  padding-top: 10px;
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
    padding: 0;
  }
`

export const Details = styled.div`
  @media (min-width: 768px) {
    margin-left: 82px;
    border-radius: 8px;
    background-color: ${({ theme }: Props) => getLightenColor(theme.color_main)};

    div {
      padding: 25px;
    }
  }
`

export const Title = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: left;
  color: #241d29;
  padding-top: 16px;
`

export const ErrorDetail = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #737373;
  padding-top: 24px;
`

export const DetailTitle = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #737373;
`

export const DetailDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  font-weight: bold;
  text-align: left;
  color: #241d29;
  padding-top: 16px;
`
export const Retry = styled.p`
  max-width: 441px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #241d29;
  padding-top: 16px;
`

export const Tip = styled.p`
  max-width: 441px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #241d29;
  padding-top: 16px;
`

export const RetryButton = styled.div`
  @media (min-width: 768px) {
    width: 106px;
  }
`
export const BackSiteButton = styled.div`
  padding-top: 5px;
  @media (min-width: 768px) {
    width: 160px;
    margin-left: 16px;
    padding: 0;
  }
`
