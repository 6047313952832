import { lighten } from 'polished'

const getLightenColor = (mainColor: string) => {
  let colorLightened
  if (mainColor === '#953BF7') {
    // Edutive color. TODO: We should consdier every pre-defined color that is light enough to be white
    colorLightened = '#eae4f2'
  } else {
    colorLightened = lighten(0.55, mainColor)
    if (colorLightened === '#fff') {
      colorLightened = '#e8e6e1'
    }
  }
  return colorLightened
}

export { getLightenColor }
