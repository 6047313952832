import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'
import { ThemeContext } from 'styled-components'

import {
  AcademyLogo,
  ButtonLogin,
  Container,
  Content,
  DevelopedBy,
  SeparatorLine,
  FooterItemTitle,
  LinkRef,
  MobileContainer,
  MobileContent,
  LoginButtons,
} from './styles'
import useGlobalContext from '../../../store/GlobalContext/useGlobalContext'
import { getFormattedUrl } from '../../../helpers/url-formatter'
import useWindowSize from '../../../helpers/useWindowSize'

const EDUTIVE_LOGO = require('./images/edutive-logo.svg')

const Footer = () => {
  const { termsUrlFromCms, privacyUrlFromCms, customMenuItemsJson } = useGlobalContext()
  const themeContext = useContext(ThemeContext)
  const { width } = useWindowSize()

  const termsUrl = getFormattedUrl(termsUrlFromCms)

  const privacyUrl = getFormattedUrl(privacyUrlFromCms)

  const customMenuItems = JSON.parse(customMenuItemsJson)

  const { t } = useTranslation()

  const col2Terms = {
    title: t('footer.termsAndConditions'),
    url: termsUrl,
    expandList: [],
  }

  const col2Privacy = {
    title: t('footer.privacyPolicies'),
    url: privacyUrl,
    expandList: [],
  }

  const handleEdutiveLogoClick = () => window.open('https://edutive.co')

  const getAcademyLogo = (): string => themeContext.logo_alt

  const getUrl = (footerItem: any) => {
    if (isEqual(footerItem.url, '/instructors') || isEqual(footerItem.url, '/products')) {
      return footerItem.url
    }

    return getFormattedUrl(footerItem.url)
  }

  if (width >= 768) {
    return (
      <Container>
        <Content>
          <div>
            <AcademyLogo>
              <img src={getAcademyLogo()} />
            </AcademyLogo>
            <LoginButtons>
              {!!customMenuItems.loginItem.is_visible && (
                <ButtonLogin href={customMenuItems.loginItem.url} target="_blank" rel="noreferrer">
                  {customMenuItems.loginItem.title}
                </ButtonLogin>
              )}
              {!!customMenuItems.instructorLoginItem.is_visible && (
                <ButtonLogin href={customMenuItems.instructorLoginItem.url} target="_blank" rel="noreferrer">
                  {customMenuItems.instructorLoginItem.title}
                </ButtonLogin>
              )}
            </LoginButtons>
          </div>
          <div>
            {customMenuItems.footerItems.map(
              (footerItem: any, index: number) =>
                !!footerItem.is_visible && (
                  <LinkRef href={getUrl(footerItem)}>
                    <FooterItemTitle>{footerItem.title}</FooterItemTitle>
                  </LinkRef>
                )
            )}
          </div>
          <div>
            {!isEqual(col2Terms.url, '//null') && (
              <LinkRef href={col2Terms.url}>
                <FooterItemTitle>{col2Terms.title}</FooterItemTitle>
              </LinkRef>
            )}
            {!isEqual(col2Privacy.url, '//null') && (
              <LinkRef href={col2Privacy.url}>
                <FooterItemTitle>{col2Privacy.title}</FooterItemTitle>
              </LinkRef>
            )}
          </div>
        </Content>
        <SeparatorLine />
        <DevelopedBy>
          <p>{t('footer.developedBy').toLowerCase()}</p>
          <img src={EDUTIVE_LOGO} onClick={handleEdutiveLogoClick} />
        </DevelopedBy>
      </Container>
    )
  }

  return (
    <MobileContainer>
      <MobileContent>
        {customMenuItems.footerItems.map(
          (footerItem: any, index: number) =>
            !!footerItem.is_visible && (
              <LinkRef href={getUrl(footerItem)}>
                <FooterItemTitle>{footerItem.title}</FooterItemTitle>
              </LinkRef>
            )
        )}
        <LinkRef href={col2Terms.url}>
          <FooterItemTitle>{col2Terms.title}</FooterItemTitle>
        </LinkRef>
        <LinkRef href={col2Privacy.url}>
          <FooterItemTitle>{col2Privacy.title}</FooterItemTitle>
        </LinkRef>
        {!!customMenuItems.loginItem.is_visible && (
          <ButtonLogin href={customMenuItems.loginItem.url} target="_blank" rel="noreferrer">
            {customMenuItems.loginItem.title}
          </ButtonLogin>
        )}
        {!!customMenuItems.instructorLoginItem.is_visible && (
          <ButtonLogin href={customMenuItems.instructorLoginItem.url} target="_blank" rel="noreferrer">
            {customMenuItems.instructorLoginItem.title}
          </ButtonLogin>
        )}
        <SeparatorLine />
        <DevelopedBy>
          <p>{t('footer.developedBy')}</p>
          <img src={EDUTIVE_LOGO} onClick={handleEdutiveLogoClick} />
        </DevelopedBy>
      </MobileContent>
    </MobileContainer>
  )
}

export default Footer
