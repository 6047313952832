import React from 'react'

import { Container, ActionContainer, DescriptionContainer, Title, Description, ButtonsContainer } from './styles'
import { MainLayout, Footer } from '../../components/common'
import { MainMenu, MainButton } from '../../components/kit'
import { useTranslation } from 'react-i18next'

type Props = {
  history: any
}

const RouteNotFound = ({ history }: Props) => {
  const { t } = useTranslation()

  return (
    <MainLayout>
      <MainMenu negative />
      <div style={{ height: '100px' }} />
      <Container>
        <Title>{t('routeNotFound.errorExclamation')}</Title>
        <DescriptionContainer>
          <Title>{t('routeNotFound.title')}</Title>
        </DescriptionContainer>
        <ActionContainer>
          <Description>{t('routeNotFound.description')}</Description>
          <ButtonsContainer>
            <MainButton label={t('routeNotFound.backHomeLabel')} onClick={() => history.push('/home')} />
          </ButtonsContainer>
        </ActionContainer>
      </Container>
      <Footer />
    </MainLayout>
  )
}

export default RouteNotFound
