import styled from 'styled-components'

type Props = {
  theme: any
  filled: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const DotContainer = styled.div`
  margin-top: 5px;
  padding: 0 4px;
`

export const Dot = styled.div`
  border: 5px solid ${(props: Props) => props.theme.color_main};
  border-radius: 100%;
  position: relative;
  width: 16px;
  height: 16px;

  ::before {
    content: '';
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${(props: Props) => props.theme.color_main};
    border-radius: 100%;
  }
`

export const Line = styled.div`
  width: 513px;
  border: 1px solid ${(props: Props) => (props.filled ? props.theme.color_main : 'black')};
  background: ${(props: Props) => (props.filled ? props.theme.color_main : 'black')};
`

export const FullLine = styled.div`
  width: 100%;
  border: 1px solid #e5e5e5;
  background: #e5e5e5;
`
