import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { isSafari } from 'react-device-detect'
import * as Yup from 'yup'

import {
  Overlay,
  OverlayBackground,
  FormInputContainer,
  SubmitButtonContainer,
  Title,
  InputWrapper,
  NameInputContainer,
  CrossIconContainer,
} from './styles'
import { MainButton, StandarInput, PhoneInput } from '../../kit'
import { getProgramPdf } from '../../../api'

import useGlobalContext from '../../../store/GlobalContext/useGlobalContext'

type Props = {
  programId?: string
  topBarTitle?: string
  onDismissOverlay: () => void
}

const ProgramModal = ({ onDismissOverlay, topBarTitle, programId = '1' }: Props) => {
  const [loading, setLoading] = useState(false)
  const phoneInputRef = useRef<any>()

  const { t } = useTranslation()

  const FormConfig: any = {
    mode: 'onBlur',
    validationSchema: Yup.object({
      name: Yup.string()
        .max(40, t('programModal.inputValidate40MaxCharacters'))
        .required(t('programModal.inputValidateRequired')),
      lastName: Yup.string()
        .max(40, t('programModal.inputValidate40MaxCharacters'))
        .required(t('programModal.inputValidateRequired')),
      email: Yup.string().email(t('programModal.inputValidateEmail')).required(t('programModal.inputValidateRequired')),
      phone: Yup.string()
        .max(20, t('programModal.inputValidate20MaxCharacters'))
        .required(t('programModal.inputValidateRequired')),
    }),
  }

  const { register, handleSubmit, errors } = useForm(FormConfig)

  const { academyId, userIsoCode } = useGlobalContext()

  useEffect(() => {
    window.onclick = (event: any) => {
      const overlayElement = document.getElementById('overlay')
      if (event.target == overlayElement) {
        onDismissOverlay()
      }
    }
  }, [])

  const onSubmit = async (values: any) => {
    setLoading(true)
    const phone = phoneInputRef.current?.getInternationalPhoneNumber()
    const country = phoneInputRef.current?.getSelectedPhoneCode()

    const payload = {
      name: values.name,
      lastname: values.lastName,
      email: values.email,
      phone,
      country,
    }

    const {
      // eslint-disable-next-line @typescript-eslint/camelcase
      data: { pres_pdf },
    } = await getProgramPdf(academyId, programId, payload)

    isSafari ? window.open(pres_pdf, '_self') : window.open(pres_pdf, '_blank')
    onDismissOverlay()
    setLoading(false)
  }

  return (
    <OverlayBackground id="overlay">
      <Overlay onSubmit={handleSubmit(onSubmit)}>
        <CrossIconContainer onClick={onDismissOverlay}>
          <img src={require('./images/cross-icon.png')} />
        </CrossIconContainer>
        <Title>{topBarTitle}</Title>
        <InputWrapper>
          <NameInputContainer>
            <StandarInput
              label={t('programModal.nameLabel')}
              placeholder={t('programModal.namePlaceholder')}
              error={errors.name}
              errorMessage={errors.name?.message}
              register={register}
              name="name"
            />
          </NameInputContainer>
          <FormInputContainer>
            <StandarInput
              label={t('programModal.lastNameLabel')}
              placeholder={t('programModal.lastNamePlaceholder')}
              error={errors.lastName}
              errorMessage={errors.lastName?.message}
              register={register}
              name="lastName"
            />
          </FormInputContainer>
        </InputWrapper>
        <FormInputContainer>
          <StandarInput
            label={t('programModal.emailLabel')}
            placeholder={t('programModal.emailPlaceholder')}
            error={errors.email}
            errorMessage={errors.email?.message}
            register={register}
            name="email"
          />
        </FormInputContainer>
        <FormInputContainer>
          <PhoneInput
            defaultIsoCode={userIsoCode}
            phoneInputErrorMessage={errors.phone?.message}
            phoneInputError={errors.phone}
            register={register}
            name="phone"
            ref={phoneInputRef}
          />
        </FormInputContainer>
        <SubmitButtonContainer>
          <MainButton label={t('programModal.sendButton')} disabled={loading} />
        </SubmitButtonContainer>
      </Overlay>
    </OverlayBackground>
  )
}

ProgramModal.propTypes = {
  programId: PropTypes.string.isRequired,
  topBarTitle: PropTypes.string.isRequired,
  onDismissOverlay: PropTypes.func.isRequired,
}

export default ProgramModal
