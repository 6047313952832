import styled from 'styled-components'

export const Container = styled.div`
  width: 386px;
  margin-bottom: 30px;
  border: 1.216px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 8.51202px 18.24px rgba(0, 0, 0, 0.14);
  -webkit-border-radius: 8.51202px;
  -moz-border-radius: 8.51202px;
  border-radius: 8.51202px;
  background-color: #ffffff;

  @media (min-width: 992px) {
    margin-bottom: 0px;
  }
`

export const BannerImage = styled.div`
  height: 113px;
  background-size: cover !important;
  box-sizing: border-box;
  border-radius: 8.51202px 8.51202px 0px 0px;
`

export const ContentContainer = styled.div`
  padding: 10px 31px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 225px;
`

export const CustomerInfoContainer = styled.div`
  overflow: hidden;
  display: flex;
`

export const CustomerNameContainer = styled.div`
  margin-top: -17px;
`

export const CustomerAvatar = styled.div`
  height: 48px;
  width: 48px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  float: left;
  background-size: cover !important;
  margin-right: 21px;
`

export const Testimony = styled.div`
  margin-bottom: 30px;
`
