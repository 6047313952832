import React from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'

import { Line, Container, DotContainer, FullLine, Dot } from './styles'

const ARRAY_LENGTH = 3

type Props = {
  filledDot?: number
}

const DotBar = ({ filledDot = 0 }: Props) => {
  const Dots = times(ARRAY_LENGTH, (index) => index <= filledDot - 1 && true)
  const getDot = (index: number) =>
    Dots[index] ? <Dot filled={false} /> : <img src={require('./images/empty-dot.png')} />

  return (
    <Container>
      <Line filled={Dots[0]} />
      <DotContainer>{getDot(0)}</DotContainer>
      <Line filled={Dots[1]} />
      <DotContainer>{getDot(1)}</DotContainer>
      <Line filled={Dots[2]} />
      <DotContainer>{getDot(2)}</DotContainer>
      <FullLine />
    </Container>
  )
}

DotBar.defaultProps = {
  filledDot: 0,
}

DotBar.propTypes = {
  filledDot: PropTypes.number,
}

export default DotBar
