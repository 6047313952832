import styled from 'styled-components'

export const CardContainer = styled.div`
  margin-bottom: 70px;

  @media (min-width: 992px) {
    width: 1044.96px;
    height: 306.63px;
    margin-bottom: 50px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;

  @media (min-width: 992px) {
    width: 1044.96px;
    height: 306.63px;
    margin-bottom: 50px;
    padding: 0px 20px;

    flex-direction: row;
    justify-content: space-between;
  }
`

export const Banner = styled.div`
  width: 312.54px;
  height: 230px;
  margin-bottom: 65px;
  background-size: cover !important;

  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;

  @media (min-width: 992px) {
    margin-bottom: 0px;
    width: 487.73px;
    height: 286.63px;
  }
`

export const TextContainer = styled.div`
  width: 312.54px;

  display: flex;
  flex-direction: column;

  @media (min-width: 992px) {
    width: auto;
  }
`

export const Title = styled.div`
  width: 310px;
  margin-bottom: 24px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
`

export const DescriptionContainer = styled.div`
  max-width: 384.24px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #52575c;
`
