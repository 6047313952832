/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { getAcademyByDomain, getAcademyByCustomDomain, AcademyThemeType, getCustomMenuItems } from './api'
import { getAcademyDomain } from './helpers/academy-name'
import { Routes } from './enums'
import ScrollToTop from './helpers/ScrollToTop'
import RouteNotFound from './screens/RouteNotFound'
import AcademyInactiveError from './screens/AcademyInactiveError'

import GlobalContext from './store/GlobalContext/GlobalContext'
import { GlobalDataThemeType } from './api/modelContext'
import { Spinner } from './components/kit'

const App = () => {
  //TODO: define a default theme in useState dafault option when Academy Theme is protertly define
  //TODO: change variable names so i don't depend of the endpoint naming, also re-organize in propertly objects ( color: {}, etc.. )
  const [academyStatus, setAcademyStatus] = useState<number>(200)
  const [academyTheme, setAcademyTheme] = useState<AcademyThemeType>({
    id: 0,
    name: 'Edutive',
    domain: 'edutive',
    custom_domain: 'custom_domain',
    mp_public: 'wefwepfiuewfhwefuwe',
    paypal_public: 'wefwepfiuewfhwefuwe',
    stripe_public: 'wefwepfiuewfhwefuwe',
    stripe_account_id: '',
    main_font: 'main font',
    favicon: '',
    color_main: 'white',
    color_secondary: 'white',
    color_accent: 'white',
    footer_code: '',
    user_iso_code: 'AR',
    language: 'es',
    header_code: '',
    logo_alt: '',
    terms: '',
    privacy: '',
    description: '',
  })
  const { i18n } = useTranslation()

  const [globalData, setGlobalData] = useState<GlobalDataThemeType>({
    customMenuItemsJson: '',
    academyId: 0,
    academyDomain: '',
    userIsoCode: '',
    academyMPPublic: '',
    academyStripePublic: '',
    academyPaypalPublic: '',
    academyStripeAccountId: '',
    websiteEnabled: true,
    allowBankTransfers: true,
    allowCashPayment: false,
    allowCreditCardPayment: false,
    bankAccountDetails: '',
    termsUrlFromCms: '',
    privacyUrlFromCms: '',
    billingInfo: false,
    edutiveLmsEnabled: false,
    customFields: [
      {
        id: 0,
        title: '',
        data_type: '',
        custom_id: '',
        mandatory: false,
      },
    ],
  })

  useEffect(() => {
    const fetchData = async () => {
      const { domain, isCustomDomain } = getAcademyDomain()
      try {
        const { data: academyData } = isCustomDomain
          ? await getAcademyByCustomDomain(domain)
          : await getAcademyByDomain(domain)

        const { data: customMenuItems } = await getCustomMenuItems(academyData.id)

        setGlobalData({
          customMenuItemsJson: JSON.stringify(customMenuItems),
          academyId: academyData.id,
          academyDomain: academyData.domain,
          userIsoCode: academyData.user_iso_code,
          academyMPPublic: academyData.mp_public,
          academyStripePublic: academyData.stripe_public,
          academyPaypalPublic: academyData.paypal_public,
          academyStripeAccountId: academyData.stripe_account_id,
          allowBankTransfers: academyData.allow_bank_transfers,
          allowCreditCardPayment: academyData.allow_credit_card,
          websiteEnabled: academyData.website_enabled,
          allowCashPayment: academyData.allow_mp_cash,
          bankAccountDetails: academyData.bank_account_details,
          termsUrlFromCms: academyData.terms,
          privacyUrlFromCms: academyData.privacy,
          billingInfo: academyData.billing_info,
          edutiveLmsEnabled: academyData.edutive_lms_enabled,
          customFields: academyData.custom_fields,
        })

        if (!isEmpty(academyData.header_code)) {
          const headerScriptStringConverted = document.createRange().createContextualFragment(academyData.header_code)
          document.head.appendChild(headerScriptStringConverted)
        }
        if (!isEmpty(academyData.footer_code)) {
          const footerScriptStringConverted = document.createRange().createContextualFragment(academyData.footer_code)
          document.body.appendChild(footerScriptStringConverted)
        }

        i18n.changeLanguage(academyData.language)
        setAcademyTheme(academyData)

        Sentry.setContext('academy', {
          id: academyData.id,
        })
      } catch (err) {
        setAcademyStatus(err.response.status)
      }
    }
    fetchData()
  }, [])

  if (globalData.academyId === 0) {
    return <Spinner />
  }
  return (
    <GlobalContext.Provider value={globalData}>
      <ThemeProvider theme={academyTheme}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{academyTheme.name}</title>
          <link rel="icon" href={academyTheme.favicon} />
          <link rel="apple-touch-icon" href={academyTheme.favicon} />
          <meta name="description" content={academyTheme.description} />
          <meta name="theme-color" content={academyTheme.color_main} />
        </Helmet>
        {academyStatus == 451 ? (
          <AcademyInactiveError />
        ) : (
          <Router>
            <ScrollToTop />
            <Switch>
              {Routes.map(({ path, component }, key) => {
                return <Route path={path} component={component} key={key} />
              })}
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route component={RouteNotFound} />
            </Switch>
          </Router>
        )}
      </ThemeProvider>
    </GlobalContext.Provider>
  )
}

export default App
