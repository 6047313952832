export enum LOADER_TYPES {
  AUDIO = 'Audio',
  BALL_TRIANGLE = 'Ball-Triangle',
  BARS = 'Bars',
  CIRCLES = 'Circles',
  GRID = 'Grid',
  HEARTS = 'Hearts',
  OVAL = 'Oval',
  PUFF = 'Puff',
  RINGS = 'Rings',
  TAIL_SPIN = 'TailSpin',
  THREE_DOTS = 'ThreeDots',
  WATCH = 'Watch',
  REVOLVING_DOT = 'RevolvingDot',
  TRIANGLE = 'Triangle',
  PLANE = 'Plane',
  MUTATING_DOTS = 'MutatingDots',
}
