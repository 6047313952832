import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { find, isEmpty, isEqual, isNil, map } from 'lodash'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'
import { useTranslation } from 'react-i18next'
import countries from './countries.json'

import { Container, DropdownInputContainer, StandarInputContainer } from './styles'
import DropdownInput from '../DropdownInput'
import StandarInput from '../StandarInput'
import '../../../../i18n'

type Props = {
  defaultIsoCode?: string
  phoneInputError?: boolean
  phoneInputErrorMessage?: string
  register?: any
  name?: string
  useAcademyColor?: boolean
  defaultPhoneNumber?: string
}

const PhoneInput = forwardRef(
  (
    {
      defaultIsoCode = 'AR',
      phoneInputError,
      phoneInputErrorMessage,
      register,
      name = '',
      useAcademyColor,
      defaultPhoneNumber = '',
    }: Props,
    ref
  ) => {
    const [showDropdownList, setShowDropdownList] = useState(false)
    const [selectedItem, setSelectedItem] = useState('')
    const [countryNameWithIsoCodeList, setCountryNameWithIsoCodeList] = useState<any[]>()
    const [selectedCountryIsoCode, setSelectedCountryIsoCode] = useState<any>('')
    const [phone, setPhone] = useState<string>('')

    const { t } = useTranslation()

    useEffect(() => {
      const fetchCountryList = async () => {
        const countryNameWithIsoCodeList = map(countries, (item) => {
          isEqual(defaultIsoCode, item.alpha2Code) && setSelectedItem(item.name)

          return {
            name: item.name,
            isoCode: item.alpha2Code,
          }
        })

        setCountryNameWithIsoCodeList(countryNameWithIsoCodeList)
        setSelectedCountryIsoCode(defaultIsoCode)
      }

      fetchCountryList()
    }, [defaultIsoCode])

    const formatPhoneNumber = (value: any) => {
      const phoneNumber = new AsYouType(selectedCountryIsoCode).input(value)

      setPhone(phoneNumber)
    }

    useEffect(() => {
      if (!isEmpty(defaultPhoneNumber)) {
        formatPhoneNumber(defaultPhoneNumber)
      }
    }, [defaultPhoneNumber])

    useImperativeHandle(ref, () => ({
      getInternationalPhoneNumber() {
        const { isoCode } = find(countryNameWithIsoCodeList, { name: selectedItem })
        const parsedPhoneNumber = parsePhoneNumber(phone, isoCode)
        const internationalPhoneNumber = parsedPhoneNumber?.formatInternational().toString()
        const formatedInternationalPhoneNumber = internationalPhoneNumber?.replace(/\s+/g, '')
        return formatedInternationalPhoneNumber
      },

      getSelectedPhoneCode() {
        const { isoCode } = find(countryNameWithIsoCodeList, { name: selectedItem })

        return isoCode
      },
    }))

    const handleDropdownListClick = () => {
      setShowDropdownList(!showDropdownList)
    }

    const handleDropdownItemClick = (value: string) => {
      const { isoCode } = find(countryNameWithIsoCodeList, { name: value })
      const parsedPhoneNumber = parsePhoneNumber(phone, isoCode)
      const nationalPhoneNumber = parsedPhoneNumber?.formatNational().toString()
      setSelectedItem(value)
      setSelectedCountryIsoCode(isoCode)
      !isNil(nationalPhoneNumber) && setPhone(nationalPhoneNumber)
      setShowDropdownList(false)
    }

    const handleChangePhoneValue = (e: any) => {
      formatPhoneNumber(e.target.value)
    }

    return (
      <Container>
        <DropdownInputContainer>
          <DropdownInput
            useAcademyColor
            dropdownList={map(countryNameWithIsoCodeList, (item) => item.name)}
            label={t('phoneInput.zipCode')}
            onDropdownItemSelect={handleDropdownItemClick}
            placeholder={selectedItem}
            selectedItem={selectedItem}
            onInputClick={handleDropdownListClick}
            showDropdownList={showDropdownList}
          />
        </DropdownInputContainer>
        <StandarInputContainer>
          <StandarInput
            useAcademyColor
            label={t('phoneInput.phone')}
            onChangeValue={handleChangePhoneValue}
            disabled={false}
            errorMessage={phoneInputErrorMessage}
            error={phoneInputError}
            value={phone}
            id="phone"
            register={register}
            name={name}
          />
        </StandarInputContainer>
      </Container>
    )
  }
)

PhoneInput.displayName = 'PhoneInput'

PhoneInput.defaultProps = {
  defaultIsoCode: 'AR',
  phoneInputError: false,
  phoneInputErrorMessage: '',
  name: '',
}

PhoneInput.propTypes = {
  defaultIsoCode: PropTypes.string,
  phoneInputError: PropTypes.bool,
  phoneInputErrorMessage: PropTypes.string,
  name: PropTypes.string,
}

export default PhoneInput
