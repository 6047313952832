import React from 'react'
import PropTypes from 'prop-types'

import { CardContainer, Container, Banner, DescriptionContainer, Title, TextContainer } from './styles'
import useWindowSize from '../../../../helpers/useWindowSize'

type CardProgramInfoProps = {
  negative?: boolean
  imageUrl: string
  title: string
  description: string
}

const CardProgramInfo = ({ title, description, imageUrl, negative }: CardProgramInfoProps) => {
  const { width } = useWindowSize()
  const background = {
    background: `url(${imageUrl}) no-repeat`,
  }

  return (
    <CardContainer>
      {negative && width >= 992 ? (
        <Container>
          <TextContainer>
            <Title>{title}</Title>
            <DescriptionContainer>{description}</DescriptionContainer>
          </TextContainer>
          <Banner style={background} />
        </Container>
      ) : (
        <Container>
          <Banner style={background} />
          <TextContainer>
            <Title>{title}</Title>
            <DescriptionContainer>{description}</DescriptionContainer>
          </TextContainer>
        </Container>
      )}
    </CardContainer>
  )
}

CardProgramInfo.defaultProps = {
  negative: false,
}

CardProgramInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  negative: PropTypes.bool,
}

export default CardProgramInfo
