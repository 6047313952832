import React from 'react'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'

import { Container, Title, Price, Description, ActionButton } from './styles'

type CardPricesProps = {
  title: string
  price: number
  description: string
  currency: string
  onSubscribeButtonClick: () => void
  free?: boolean
  buttonLabel: string
}

const CardPrices = ({
  title,
  price,
  description,
  currency,
  onSubscribeButtonClick,
  free = false,
  buttonLabel,
}: CardPricesProps) => {
  const { t } = useTranslation()

  const priceCurrency = isNil(currency) ? '$' : currency

  return (
    <Container>
      <Title>{title}</Title>
      <Price>{free ? t('cardPrice.free') : `${priceCurrency}${price}`}</Price>
      <Description>{description}</Description>
      <ActionButton onClick={onSubscribeButtonClick}>
        <p>{buttonLabel}</p>
      </ActionButton>
    </Container>
  )
}

export default CardPrices
