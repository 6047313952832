import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from './locales/resources'

i18n.use(initReactI18next).init({
  resources,
  lng: 'es',
  detection: {
    order: ['queryString', 'cookie'],
    cache: ['cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
