import styled from 'styled-components'

export const Container = styled.div`
  padding: 105px 39px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: 0 auto;

  p {
    margin: 0;
  }
`

export const Description = styled.div`
  width: 599.42px;

  padding-top: 18px;
  padding-bottom: 33px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;

  @media (min-width: 768px) {
    padding-bottom: 92px;
  }
`

export const FaqsList = styled.div`
  /* margin-top: 92px; */
`

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 23px;
`

export const FaqElementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 14px 10px 25px;
  width: 317px;
  background: #ffffff;
  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;

  @media (min-width: 768px) {
    width: 677px;
    min-height: 48.91px;
    padding: 0px 20px;
    flex-direction: row;
  }
`

export const FaqQuestion = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  width: 210px;

  color: #000000;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    width: auto;
  }
`

export const FaqQuestionIcon = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`

export const FaqAnswerContainer = styled.div`
  margin-top: 31.23px;
  margin-bottom: 10px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  width: 330px;

  color: #979797;

  @media (min-width: 768px) {
    width: 677px;
    text-align: left;
  }
`
