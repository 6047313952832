import { useEffect, useState } from 'react'

const useLoadImage = (src: string) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const imageLoader = new Image()
    imageLoader.src = src

    imageLoader.onload = () => {
      setIsLoaded(true)
    }
  })

  return isLoaded
}

export default useLoadImage
