import React from 'react'
import PropTypes from 'prop-types'

import { LinkRef, Container, Label } from './styles'

type SecondaryButtonProps = {
  url: string
  label: string
  blank: boolean | undefined
}

const SecondaryButton = ({ url, label, blank }: SecondaryButtonProps) => (
  <LinkRef to={url} target={blank ? '_blank' : undefined}>
    <Container>
      <Label>{label}</Label>
    </Container>
  </LinkRef>
)

SecondaryButton.defaultProps = {
  blank: undefined,
}

SecondaryButton.propTypes = {
  blank: PropTypes.bool,
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default SecondaryButton
