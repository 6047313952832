import styled from 'styled-components'

import { COLORS } from '../Colors'

type TextProps = {
  sizeText?: number
  negative?: boolean
}

export const Hero = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 58px;
  text-align: center;
  margin: 0 0 15px;
  color: ${(props: TextProps) => (props.negative ? COLORS.SECONDARY_COLOR : COLORS.BLACK)};
`

export const DynamicHero = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: ${(props: TextProps) => `${props.sizeText}px`};
  line-height: 58px;
  text-align: center;
  color: ${(props: TextProps) => (props.negative ? COLORS.SECONDARY_COLOR : COLORS.BLACK)};
`

export const HeroDescription = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;
`

export const H1 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 45px;
  line-height: 54px;
`

export const FormLabel = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: rgba(0, 19, 47, 0.47);
`

export const H2 = styled.p`
  color: black;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 37px;
  font-family: 'Roboto';
`

export const H3 = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #000000;
`

export const BodyTitle = styled.p`
  color: ${(props: TextProps) => (props.negative ? COLORS.SECONDARY_COLOR : COLORS.BLACK)};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`

export const MenuMobileItemTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${(props: TextProps) => (props.negative ? '#979797' : '#52575c')};
`

export const DynamicMenuMobileItemTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: ${(props: TextProps) => `${props.sizeText}px`};
  line-height: 23px;
  color: ${(props: TextProps) => (props.negative ? '#979797' : '#52575c')};
`

export const MenuItemTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: ${(props: TextProps) => (props.negative ? '#979797' : '#52575c')};

  &:hover {
    color: #000000;
  }
`

export const FooterItemTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
`

export const MobileMenuSubItemTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #52575c;
`

export const BodyText = styled.p`
  color: ${(props: TextProps) => (props.negative ? COLORS.SECONDARY_COLOR : COLORS.BLACK)};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Roboto';
`
