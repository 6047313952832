import styled from 'styled-components'

type InterProps = {
  theme: any
}

type Props = {
  disabled: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 14px 0 rgba(32, 26, 36, 0.24);
  padding: 15px;
`

export const CouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TopCouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`

export const BoldText = styled.text`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: 10px;
`

export const ChevronIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const CenterCouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  justify-content: space-around;
`

export const RegularText = styled.text`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 5px;
`

export const DateContainer = styled.text`
  margin: 16px 0 32px;
  display: flex;
  flex-direction: column;
`

export const CouponContainer = styled.div`
  height: 45px;
`

export const CouponCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`

export const RegularGrayText = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737373;
`

export const DeleteCouponWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
`

export const DeleteButton = styled.button`
  background: transparent;
  border: none;
`

export const DeleteButtonText = styled.text`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${({ theme }: InterProps) => theme.color_main};
`

export const PlaceCouponWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
`

export const CouponInputWrapper = styled.div`
  width: 100%;
`

export const ApplyButton = styled.button`
  height: 35px;
  width: 100%;
  margin-top: 10px;
  border: 1.5px solid ${({ disabled }: Props) => (disabled ? '#D9D9D9' : 'black')};
  box-sizing: border-box;
  border-radius: 8px;
  background: transparent;
`

export const ApplyButtonText = styled.text`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  color: ${({ disabled }: Props) => (disabled ? '#D9D9D9' : 'black')};
`

export const CouponErrorText = styled.text`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: red;
`

export const BottomCouponWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
`

export const BoldGrayText = styled.text`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737373;
`

export const ButtonWrapper = styled.div`
  justify-content: center;
  align-items: center;
`
