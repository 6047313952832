import styled from 'styled-components'

type Props = {
  clicked: boolean
  theme: any
}

export const DrawerMenuContainer = styled.div`
  padding: 0 0 10px 39px;
  width: 100%;
  p {
    margin: 0;
  }
`

export const DrawerTitleContainer = styled.div`
  background-color: ${({ clicked, theme }: Props) => (clicked ? theme.color_main : undefined)};
  border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  padding: 23px 0 17px 36px;
`

export const ItemListContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ItemIcon = styled.img`
  width: 30px;
`

export const DropdownItemsContainer = styled.div`
  padding: 30px 0 0 55px;
`

export const DropdownItemTitleContainer = styled.div`
  padding-bottom: 23px;
  width: 160px;
`

export const LinkRef = styled.a`
  text-decoration: none;
`

export const ActionButtonContainer = styled.div`
  cursor: pointer;
  padding-top: 3px;
`

export const SeeAllTitle = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;

  color: ${(props: any) => props.theme.color_main};
`
