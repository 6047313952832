import styled from 'styled-components'
import { getLightenColor } from '../../../../helpers/lightenColor'

type Props = {
  selected: boolean
  theme: any
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  background-color: ${({ selected, theme }: Props) => (selected ? getLightenColor(theme.color_main) : '#ffffff')};
  box-shadow: 0 6.4px 13.8px 0 rgba(0, 0, 0, 0.14);
  border-radius: 12px;
  cursor: pointer;
`

export const RadioButton = styled.svg`
  width: 17px;
  height: 17px;
  margin-right: 20px;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoWrapperDesktop = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
`

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

export const Title = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
`

export const Description = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  padding: 8px 0;
  margin-top: 8px;
`

export const Price = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
`
