import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { H3, BodyText } from '../../Typography'

import { CardContainer, InstructorAvatar, InstructorInfo, InstructorDescription, ActionButtonContainer } from './styles'
import { SecondaryButton } from '../../Buttons'

type CardInstructorsProps = {
  url: string
  name: string
  lastName: string
  occupation: string
  avatarUrl: string
}

const CardInstructors = ({ url, name, lastName, occupation, avatarUrl }: CardInstructorsProps) => {
  const { t } = useTranslation()
  const background = {
    background: `url(${avatarUrl}) no-repeat`,
  }

  return (
    <CardContainer>
      <InstructorAvatar style={background} />
      <InstructorInfo>
        <H3>
          {name} {lastName}
        </H3>
        <InstructorDescription>
          <BodyText style={{ textAlign: 'center' }}>{occupation}</BodyText>
        </InstructorDescription>
        <ActionButtonContainer>
          <SecondaryButton url={url} label={t('cardInstructor.learnMoreLabel')} />
        </ActionButtonContainer>
      </InstructorInfo>
    </CardContainer>
  )
}

CardInstructors.propTypes = {
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  occupation: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
}

export default CardInstructors
