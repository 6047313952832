import React from 'react'
import Loader from 'react-loader-spinner'

import { Container } from './styles'

type PaymentButtonProps = {
  backgroundColor?: string
  primary?: boolean
  disabled?: boolean
  loading?: boolean
  label: string
  onClick?: (event?: any) => void
  type?: 'button' | 'reset' | 'submit'
  form?: string
  id?: string
}

const PaymentButton: React.FC<PaymentButtonProps> = ({
  primary = true,
  disabled = false,
  loading = false,
  onClick,
  backgroundColor,
  label,
  ...props
}: PaymentButtonProps) => (
  <Container
    primary={primary}
    backgroundColor={backgroundColor}
    disabled={disabled}
    onClick={disabled || loading ? undefined : onClick}
    {...props}
  >
    {loading ? <Loader type="ThreeDots" color="gray" height={25} width={25} /> : label}
  </Container>
)

export default PaymentButton
