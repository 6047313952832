import axios from '../../utils/axios'

export const getDocumentTypes = async (academyId: number) =>
  await axios.get(`/academies/${academyId}/transactions/mp/indentification/types`)

export const confirmMercadoPagoPayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/mp`, payload)

export const confirmMercadoPagoSubscriptionPayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/subscription/mercadopago`, payload)

export const confirmMercadoPagoCashPayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/mp-cash`, payload)

export const confirmTransferPayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/transfer`, payload)

export const confirmPayPalPayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/paypal`, payload)

export const confirmStripePayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/stripe`, payload)

export const confirmStripeSubscriptionPayment = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/subscription/stripe`, payload)

export const confirmFreeTransaction = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/free`, payload)

export const confirmCustomTransaction = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/transactions/payment/custom`, payload)

export const confirmPerson = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/people`, payload)

export const postOrder = async (academyId: number, payload: any) =>
  await axios.post(`/academies/${academyId}/order`, payload)

export const getOrder = async (academyId: number, orderId: string) =>
  await axios.get(`/academies/${academyId}/order/${orderId}`)

export const getAcademyCouponByCode = async (academyId: number, couponCode: string, programId?: number) =>
  await axios.get(`/academies/${academyId}/program/${programId}/coupons/${couponCode}`)

export const getBillingInfo = async (academyId: number) => await axios.get(`/academies/${academyId}`)

export const getAcademyPaymentsMethod = async (key: string) =>
  await axios.get(`https://api.mercadopago.com/v1/payment_methods?public_key=${key}`)

export const triggerSelectPriceEvent = (academyId: number, payload: any) =>
  axios.post(`/academies/${academyId}/payment-type`, payload)
