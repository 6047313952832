import styled from 'styled-components'

export const MainLayout = styled.div`
  width: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
`

export const GraySectionContainer = styled.div`
  width: 100%;
  background-color: rgba(224, 224, 236, 0.27);
`

export const WhiteSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  background-color: rgba(255, 255, 255);
`
