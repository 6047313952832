import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toUpper, isEmpty, isNil } from 'lodash'

import { StandarInput } from '../../kit'
import { PriceType, getAcademyCouponByCode } from '../../../api'
import useGlobalContext from '../../../store/GlobalContext/useGlobalContext'
import PriceTypes from '../../../enums/PriceTypes'

import * as S from './styles'

const CHEVRON_UP = require('./images/chevron-up.svg')
const CHEVRON_DOWN = require('./images/chevron-down.svg')

type Props = {
  showCouponInfo: boolean
  disabled?: boolean
  type?: 'button' | 'submit'
  footerData: any
  price: PriceType
  openCoupon?: () => void
  coupon?: any
}

const CheckoutFooter = forwardRef(
  ({ showCouponInfo, footerData, price, openCoupon, coupon, disabled = false }: Props, ref) => {
    const { academyId } = useGlobalContext()
    const { t } = useTranslation()

    const [openFooter, setOpenFooter] = useState<boolean>(false)
    const [valueCouponCode, setValueCouponCode] = useState<string>('')
    const [couponCode, setCouponCode] = useState<string>('')
    const [totalAmount, setTotalAmount] = useState<number>(price?.price)
    const [couponError, setCouponError] = useState<boolean>(false)

    const handleChangeCouponValue = (e: any) => {
      setCouponError(false)
      setValueCouponCode(e.target.value)
    }

    const getTotalAmountText = (currency: string, totalAmount: number) => {
      const roundedTotalAmount = Math.round(totalAmount * 100) / 100
      if (price.type === PriceTypes.SUBSCRIPTION) {
        return `${currency} ${roundedTotalAmount} / ${
          price.recurrence === 'MONTHLY' ? t('cardPrice.month') : t('cardPrice.annual')
        }`
      }

      return `${currency} ${roundedTotalAmount}`
    }

    useImperativeHandle(ref, () => ({
      openOverlay() {
        setOpenFooter(true)
      },
    }))

    const handleDeleteCoupon = () => {
      setValueCouponCode('')
      setCouponCode('')
      const amount = price?.national ? price?.price : price?.price_international
      setTotalAmount(amount)
      footerData.onDeleteButton(amount)
    }

    const calculateTotalAmount = (coupon: any) => {
      let amount = footerData.national ? price.price : price.price_international
      if (!isEmpty(coupon)) {
        const discountAmount = (totalAmount * coupon.discount_amount) / 100
        amount = totalAmount - discountAmount
        setCouponCode(coupon.code)
      }
      setTotalAmount(amount)
      return amount
    }

    useEffect(() => {
      calculateTotalAmount(coupon)
    }, [price])

    const handleApplyCoupon = async () => {
      try {
        const { data: academyCouponCode } = await getAcademyCouponByCode(academyId, valueCouponCode, price?.program_id)
        const amount = calculateTotalAmount(academyCouponCode)
        footerData.onApplyCoupon(academyCouponCode, amount)
        setValueCouponCode('')
      } catch (error) {
        setCouponError(true)
        handleDeleteCoupon()
      }
    }

    const handleChevronPress = () => {
      setOpenFooter((prevState) => !prevState)
      !isNil(openCoupon) && openCoupon()
    }

    const renderDate = () => {
      if (!isEmpty(footerData.startDate) && !isEmpty(footerData.endDate)) {
        return t('footerCheckout.date', { startDate: footerData.startDate, endDate: footerData.endDate })
      } else if (!isEmpty(footerData.startDate)) {
        return t('footerCheckout.onlyStartDate', { startDate: footerData.startDate })
      } else if (!isEmpty(footerData.endDate)) {
        return t('footerCheckout.onlyEndDate', { endDate: footerData.endDate })
      }
    }

    return (
      <S.Container>
        {showCouponInfo && (
          <S.CouponWrapper>
            <S.TopCouponWrapper>
              <S.ChevronIcon src={openFooter ? CHEVRON_DOWN : CHEVRON_UP} onClick={handleChevronPress} />
            </S.TopCouponWrapper>
            {openFooter && (
              <S.CenterCouponWrapper>
                <S.BoldText>{footerData.title}</S.BoldText>
                <S.DateContainer>
                  <S.RegularText>{renderDate()}</S.RegularText>
                  {!isEmpty(footerData.dateDetails) && <S.RegularText>{footerData.dateDetails}</S.RegularText>}
                </S.DateContainer>
                {!isEmpty(couponCode) && (
                  <S.CouponCodeWrapper>
                    <S.RegularGrayText>{toUpper(t('footerCheckout.couponTitle'))}</S.RegularGrayText>
                    <S.DeleteCouponWrapper>
                      <S.RegularText>{couponCode}</S.RegularText>
                      <S.DeleteButton onClick={handleDeleteCoupon}>
                        <S.DeleteButtonText>{t('footerCheckout.delete')}</S.DeleteButtonText>
                      </S.DeleteButton>
                    </S.DeleteCouponWrapper>
                  </S.CouponCodeWrapper>
                )}
                <S.PlaceCouponWrapper>
                  <S.CouponInputWrapper>
                    <StandarInput
                      placeholder={t('footerCheckout.couponPlaceholder')}
                      onChangeValue={handleChangeCouponValue}
                      value={valueCouponCode}
                      disabled={!isEmpty(couponCode) || price.type !== PriceTypes.ONE_TIME || disabled}
                    />
                  </S.CouponInputWrapper>
                  <S.ApplyButton
                    disabled={!isEmpty(couponCode) || price.type !== PriceTypes.ONE_TIME || disabled}
                    onClick={handleApplyCoupon}
                    type="button"
                  >
                    <S.ApplyButtonText
                      disabled={!isEmpty(couponCode) || price.type !== PriceTypes.ONE_TIME || disabled}
                    >
                      {t('footerCheckout.apply')}
                    </S.ApplyButtonText>
                  </S.ApplyButton>
                </S.PlaceCouponWrapper>
                {couponError && <S.CouponErrorText>{t('footerCheckout.couponError')}</S.CouponErrorText>}
              </S.CenterCouponWrapper>
            )}
            <S.BottomCouponWrapper>
              <S.BoldGrayText>{toUpper(t('footerCheckout.total'))}</S.BoldGrayText>
              {totalAmount === 0 || isNil(totalAmount) ? (
                <S.BoldText>{t('footerCheckout.free')}</S.BoldText>
              ) : (
                <S.BoldText>{getTotalAmountText(footerData.currency, totalAmount)}</S.BoldText>
              )}
            </S.BottomCouponWrapper>
          </S.CouponWrapper>
        )}
      </S.Container>
    )
  }
)

CheckoutFooter.displayName = 'CheckoutFooter'

export default CheckoutFooter
