import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil, isEqual, take } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BodyTitle } from '../../Typography'
import {
  MenuItemContainer,
  ExpandIconContainer,
  MenuDropdownContainer,
  SubItemContainer,
  TypeName,
  ExpandIcon,
  ProgramListContainer,
  ProgramList,
  ProgramItem,
  ProgramNameContainer,
  ProgramName,
  Divider,
  ActionButtonContainer,
  SeeAllTypeActionButtonContainer,
  SeeAllTitle,
  LinkRef,
  DropdownContainer,
} from './styles'

const NEGATIVE_EXPAND_ICON = require('./images/expand-negative-icon.png')
const LEFT_ARROW_ICON = require('./images/left-arrow.png')

type DesktopMenuItemProps = {
  title: string
  subItems: any
  showDropdown: boolean
  url: string
  blank: boolean
  negative: boolean
}

const DesktopMenuItem = ({ title, subItems, showDropdown, url, blank, negative }: DesktopMenuItemProps) => {
  const [showProgramList, setShowProgramList] = useState(undefined)
  const { t } = useTranslation()

  const handleSeeAllButtonPress = () => {
    window.open('/products', '_blank')
  }

  const handleSeeAllProgramsByTypeButtonPress = (typeId: number) => {
    window.open(`/products/${typeId}`, '_blank')
  }

  const handleProgramClicked = useCallback(
    (programSlug: any) => {
      window.open(`/product/${programSlug}`, '_self')
    },
    [subItems]
  )

  return (
    <div>
      <LinkRef href={url} target={blank ? '_blank' : '_self'}>
        <MenuItemContainer>
          <BodyTitle>{title}</BodyTitle>
          {!isEmpty(subItems) && (
            <ExpandIconContainer>
              <ExpandIcon src={NEGATIVE_EXPAND_ICON} />
            </ExpandIconContainer>
          )}
        </MenuItemContainer>
      </LinkRef>

      {showDropdown && !isEmpty(subItems) && (
        <DropdownContainer>
          <MenuDropdownContainer>
            <div style={{ paddingTop: 17 }} />
            <div>
              {take(subItems, 4).map((subItem: any, subItemIndex: any) => {
                const largeWidth = false

                return (
                  !isEmpty(subItem.programs) && (
                    <div key="1">
                      <div>
                        <SubItemContainer onMouseOver={() => setShowProgramList(subItemIndex)}>
                          <TypeName>{subItem.type_name}</TypeName>
                          <img src={LEFT_ARROW_ICON} />
                        </SubItemContainer>
                      </div>
                      {isEqual(showProgramList, subItemIndex) && (
                        <ProgramListContainer style={largeWidth ? { width: 466 } : undefined}>
                          <ProgramList>
                            {!isNil(subItem.programs) &&
                              take(subItem.programs, 3).map((program: any, programIndex: any) => (
                                <ProgramItem key="1">
                                  <ProgramNameContainer onClick={() => handleProgramClicked(program.slug)}>
                                    <ProgramName>{program.program_title}</ProgramName>
                                  </ProgramNameContainer>
                                  {subItem.programs.length > 1 &&
                                    !isEqual(programIndex, 2) &&
                                    !isEqual(programIndex, 5) && <Divider />}
                                </ProgramItem>
                              ))}
                          </ProgramList>
                          <ActionButtonContainer onClick={() => handleSeeAllProgramsByTypeButtonPress(subItem.type_id)}>
                            <SeeAllTitle>{t('dropdownMenu.seeAll')}</SeeAllTitle>
                          </ActionButtonContainer>
                        </ProgramListContainer>
                      )}
                    </div>
                  )
                )
              })}
            </div>
            <SeeAllTypeActionButtonContainer onClick={handleSeeAllButtonPress}>
              <SeeAllTitle>{t('dropdownMenu.seeAll')}</SeeAllTitle>
            </SeeAllTypeActionButtonContainer>
          </MenuDropdownContainer>
        </DropdownContainer>
      )}
    </div>
  )
}

DesktopMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  subItems: PropTypes.array,
  showDropdown: PropTypes.bool.isRequired,
  url: PropTypes.string,
  blank: PropTypes.bool.isRequired,
  light: PropTypes.bool,
}

DesktopMenuItem.defaultProps = {
  subItems: [],
  url: null,
  light: false,
}

export default DesktopMenuItem
