import React from 'react'
import PropTypes from 'prop-types'

import { StyledMainButton, TextButton } from './styles'
import Spinner from '../../Loadings/Spinner'

type MainButtonProps = {
  onClick?: () => void
  label: string
  negative: boolean
  loading: boolean
  disabled: boolean
  type?: 'button' | 'submit'
}

const MainButton = ({ onClick, negative, loading, disabled, label, type = 'submit' }: MainButtonProps) => (
  <StyledMainButton onClick={disabled ? undefined : onClick} negative={negative} disabled={disabled} type={type}>
    {loading ? <Spinner /> : <TextButton>{label}</TextButton>}
  </StyledMainButton>
)

MainButton.defaultProps = {
  negative: false,
  disabled: false,
  loading: false,
  type: 'submit',
}

MainButton.propTypes = {
  negative: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
}

export default MainButton
