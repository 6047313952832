import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { times } from 'lodash'
import { ThemeContext } from 'styled-components'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import useWindowSize from '../../../helpers/useWindowSize'
import { COLORS } from '../Colors'

type Props = {
  steps: number
  currentStep: number
  paymentError?: boolean
}

const ProgressLine = ({ steps, currentStep, paymentError = false }: Props) => {
  const { t } = useTranslation()
  const themeContext = useContext(ThemeContext)
  const { color_main: mainColor } = themeContext
  const { width } = useWindowSize()
  const isMobile = width <= 768

  const getDescription = (position: number) => {
    let description = `1. ${t('progressLine.step1')}`
    if (steps === 4) {
      switch (position) {
        case 2:
          description = `2. ${t('progressLine.step2')}`
          break
        case 3:
          description = `3. ${t('progressLine.step3')}`
          break
        case 4:
          description = `4. ${t('progressLine.step4')}`
          break
      }
    } else {
      switch (position) {
        case 1:
          description = `1. ${t('progressLine.step2')}`
          break
        case 2:
          description = `2. ${t('progressLine.step3')}`
          break
        case 3:
          description = `3. ${t('progressLine.step4')}`
          break
        case 4:
          description = `4. ${t('progressLine.step4')}`
          break
      }
    }

    if (isMobile) {
      if (currentStep === position) {
        return <S.Description>{description}</S.Description>
      }
    } else {
      return (
        <S.IconDescription color={currentStep === position ? mainColor : COLORS.GRAY_03}>
          {description}
        </S.IconDescription>
      )
    }
  }

  const getStrokeColor = (position: number) => {
    if (position === currentStep) {
      return mainColor
    }

    if (position === 4 && paymentError) {
      return 'red'
    }

    return '#D9D9D9'
  }

  return (
    <S.Container>
      {times(steps, (index) => {
        const position = index + 1

        return (
          <S.StepContainer>
            <S.LineContainer>
              <S.Line filled={position <= currentStep} />
              <div>
                <S.StepIcon xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  {position < currentStep ? (
                    <>
                      <circle cx="12" cy="12" r="12" fill={mainColor} />
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M10.935 13.602L15.537 9l1.23 1.23-5.832 5.83-3.223-3.222 1.23-1.23 1.993 1.994z"
                        clipRule="evenodd"
                      />
                    </>
                  ) : (
                    <circle cx="12" cy="12" r="11.25" stroke={getStrokeColor(position)} strokeWidth="1.5" />
                  )}
                </S.StepIcon>
              </div>
            </S.LineContainer>
            {getDescription(position)}
          </S.StepContainer>
        )
      })}
    </S.Container>
  )
}

export default ProgressLine
