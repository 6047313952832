import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }
`

export const DropdownInputContainer = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    margin-top: 2px;
  }
`

export const StandarInputContainer = styled.div`
  width: 100%;
  margin-top: 16px;

  @media (min-width: 768px) {
    margin: 0 0 0 16px;
  }
`
