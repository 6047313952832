import { createContext } from 'react'
import { GlobalDataThemeType } from '../../api/modelContext'

const GlobalContext = createContext<GlobalDataThemeType>({
  customMenuItemsJson: '',
  academyId: 0,
  academyDomain: '',
  userIsoCode: '',
  academyMPPublic: '',
  academyStripePublic: '',
  academyPaypalPublic: '',
  academyStripeAccountId: '',
  websiteEnabled: true,
  allowBankTransfers: true,
  allowCashPayment: false,
  allowCreditCardPayment: false,
  bankAccountDetails: '',
  termsUrlFromCms: '',
  privacyUrlFromCms: '',
  billingInfo: false,
  edutiveLmsEnabled: false,
  customFields: [
    {
      id: 0,
      title: '',
      data_type: '',
      custom_id: '',
      mandatory: false,
    },
  ],
})

export default GlobalContext
