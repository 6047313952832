import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  padding: 0 32px;
  background-color: white;
`

export const ErrorInfo = styled.main`
  height: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`

export const MainInfo = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
`

export const EdutiveCta = styled.div`
  height: 60px;
  color: #bfbfbf;
  a {
    text-decoration: none;
  }
`

export const ErrorIcon = styled.img`
  margin-bottom: 20px;
  width: 79px;
`

export const LogoStandard = styled.img`
  width: 131px;
  margin-bottom: 20px;
`

export const ErrorBackground = styled.img`
  max-width: 0px;
  @media (min-width: 768px) {
    min-width: 400px;
    max-width: 840px;
  }
`
