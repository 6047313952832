import React from 'react'
import { useTranslation } from 'react-i18next'
import { includes, isEmpty } from 'lodash'

import {
  Container,
  Divider,
  ContentWrapper,
  ModalityLabel,
  Month,
  MonthDescription,
  ProgramDate,
  ProgramCalendar,
  ProgramHour,
  ProgramMonth,
  CalendarEvent,
  RecurrentEvent,
  RecurrentEventDescription,
  Subscribe,
  Quota,
  SubscribeButton,
} from './styles'
import AcademyId from '../../../../enums/AcademyId'
import useGlobalContext from '../../../../store/GlobalContext/useGlobalContext'

type CardEditionProps = {
  month: string
  description: string
  modality: string
  calendarEvent: string
  dateDetails: string
  recurrentEventDescription: string
  quota: string
  onSubscribeButtonClick: () => void
}

const CardEdition = ({
  month,
  description,
  modality,
  calendarEvent,
  dateDetails,
  recurrentEventDescription,
  quota,
  onSubscribeButtonClick,
}: CardEditionProps) => {
  const { t } = useTranslation()
  const { academyId } = useGlobalContext()
  const hasDates = !includes(calendarEvent, 'undefined') || !isEmpty(dateDetails)

  return (
    <Container>
      <ContentWrapper>
        <ProgramMonth>
          <Month>{month}</Month>
          <MonthDescription>{description}</MonthDescription>
          <ModalityLabel>{modality}</ModalityLabel>
        </ProgramMonth>
        {hasDates && <Divider />}
        {hasDates && (
          <ProgramDate>
            {!includes(calendarEvent, 'undefined') && (
              <ProgramCalendar>
                <img src={require('./images/calendar-icon.svg')} />
                <div>
                  <CalendarEvent>{calendarEvent}</CalendarEvent>
                </div>
              </ProgramCalendar>
            )}
            {!isEmpty(dateDetails) && (
              <ProgramHour>
                <img src={require('./images/clock-icon.svg')} />
                <div>
                  <div>
                    <RecurrentEvent>{dateDetails}</RecurrentEvent>
                    <RecurrentEventDescription>{recurrentEventDescription}</RecurrentEventDescription>
                  </div>
                </div>
              </ProgramHour>
            )}
          </ProgramDate>
        )}
        {hasDates && <Divider />}
        <Subscribe>
          <Quota>{quota}</Quota>
          <SubscribeButton onClick={onSubscribeButtonClick} id="subscribeButton">
            {t('program.subscribe')}
          </SubscribeButton>
        </Subscribe>
      </ContentWrapper>
    </Container>
  )
}

export default CardEdition
