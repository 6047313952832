import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 160px 70px;
  align-items: center;
`

export const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 58px;
  color: #000000;
  text-align: center;
`

export const Description = styled.p`
  width: 432px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 24px;

  text-align: center;

  color: #000000;
`

export const DescriptionContainer = styled.div`
  margin-top: -100px;
`

export const ActionContainer = styled.div`
  margin-top: -20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`
