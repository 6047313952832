import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { ThemeContext } from 'styled-components'

import * as S from './styles'

const LOCK_ICON = require('./images/lock-icon.svg')

type PaymentMethodProps = {
  onSelect: (cashMethod?: string) => void
  selected: boolean
  label: string
  cashMethods?: any
}

const CardPaymentMethod = ({ onSelect, selected, label, cashMethods }: PaymentMethodProps) => {
  const { t } = useTranslation()
  const themeContext = useContext(ThemeContext)
  const { color_main: mainColor } = themeContext

  const [cashMethodSelected, setCashMethodSelected] = useState<string>('')

  useEffect(() => {
    !isEmpty(cashMethods) && setCashMethodSelected(cashMethods[0].id)
  }, [])

  const handleSelectCashMethod = (cashMethod: string) => {
    setCashMethodSelected(cashMethod)
  }

  const handleSelectPaymentMethod = () => {
    if (label === t('paymentCheckout.payWithCash')) {
      setCashMethodSelected(cashMethods[0].id)
    } else {
      setCashMethodSelected('')
    }
    onSelect(cashMethodSelected)
  }

  return (
    <S.Container>
      <S.CardWrapper selected={selected} onClick={handleSelectPaymentMethod}>
        {selected ? (
          <S.RadioButton xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
            <circle cx="8.5" cy="8.5" r="4.857" fill={mainColor} />
            <circle cx="8.5" cy="8.5" r="7.75" stroke={mainColor} strokeWidth="1.5" />
          </S.RadioButton>
        ) : (
          <S.RadioButton xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none" viewBox="0 0 17 17">
            <circle cx="8.5" cy="8.5" r="7.75" stroke="#241D29" strokeWidth="1.5" />
          </S.RadioButton>
        )}
        <S.InfoWrapper>
          <S.Title>{label}</S.Title>
          {label === t('paymentCheckout.payWithCreditCard') && (
            <S.SecurePayment>
              <img src={LOCK_ICON} />
              <p>{t('paymentCheckout.securePayment')}</p>
            </S.SecurePayment>
          )}
        </S.InfoWrapper>
      </S.CardWrapper>
      {selected &&
        label === t('paymentCheckout.payWithCash') &&
        cashMethods.map((item: any) => {
          return (
            <S.CashOption onClick={() => handleSelectCashMethod(item.id)} key={item.id}>
              {item.id === cashMethodSelected ? (
                <S.RadioButton
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="none"
                  viewBox="0 0 17 17"
                >
                  <circle cx="8.5" cy="8.5" r="4.857" fill={mainColor} />
                  <circle cx="8.5" cy="8.5" r="7.75" stroke={mainColor} strokeWidth="1.5" />
                </S.RadioButton>
              ) : (
                <S.RadioButton
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  fill="none"
                  viewBox="0 0 17 17"
                >
                  <circle cx="8.5" cy="8.5" r="7.75" stroke="#241D29" strokeWidth="1.5" />
                </S.RadioButton>
              )}
              <S.Title>{item.name}</S.Title>
            </S.CashOption>
          )
        })}
    </S.Container>
  )
}

export default CardPaymentMethod
