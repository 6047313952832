import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faRunning,
  faHeart,
  faThumbsUp,
  faSpa,
  faBeer,
  faAddressBook,
  faAward,
  faBalanceScale,
  faBook,
  faBrain,
  faBreadSlice,
  faBriefcase,
  faCalendarAlt,
  faCarrot,
  faChalkboardTeacher,
  faChartLine,
  faChartPie,
  faChild,
  faCloudDownloadAlt,
  faComments,
  faCompass,
  faGlobe,
  faGraduationCap,
  faIcons,
  faLaptopHouse,
  faPaperPlane,
  faPowerOff,
  faRss,
  faSeedling,
  faSignal,
  faStar,
  faMusic,
  faPlay,
  faVolumeUp,
} from '@fortawesome/free-solid-svg-icons'
import useWindowSize from '../../../../helpers/useWindowSize'

import { Container, TextContainer, IconContainer, Title, Description } from './styles'

type CardBenefitProps = {
  iconUrl: any
  title: string
  description: string
}

const CardBenefit = ({ iconUrl, title, description }: CardBenefitProps) => {
  const { width } = useWindowSize()

  const getIcon = () => {
    switch (iconUrl) {
      case 'fas fa-running':
        return faRunning
        break
      case 'fas fa-heart':
        return faHeart
        break
      case 'fas fa-thumbs-up':
        return faThumbsUp
        break
      case 'fas fa-address-book':
        return faAddressBook
        break
      case 'fas fa-award':
        return faAward
        break
      case 'fas fa-balance-scale':
        return faBalanceScale
        break
      case 'fas fa-beer':
        return faBeer
        break
      case 'fas fa-book':
        return faBook
        break
      case 'fas fa-brain':
        return faBrain
        break
      case 'fas fa-bread-slice':
        return faBreadSlice
        break
      case 'fas fa-briefcase':
        return faBriefcase
        break
      case 'fas fa-calendar-alt':
        return faCalendarAlt
        break
      case 'fas fa-carrot':
        return faCarrot
        break
      case 'fas fa-chalkboard-teacher':
        return faChalkboardTeacher
        break
      case 'fas fa-chart-line':
        return faChartLine
        break
      case 'fas fa-chart-pie':
        return faChartPie
        break
      case 'fas fa-child':
        return faChild
        break
      case 'fas fa-cloud-download':
        return faCloudDownloadAlt
        break
      case 'fas fa-comments':
        return faComments
        break
      case 'fas fa-compass':
        return faCompass
        break
      case 'fas fa-globe':
        return faGlobe
        break
      case 'fas fa-graduation-cap':
        return faGraduationCap
        break
      case 'fas fa-icons':
        return faIcons
        break
      case 'fas fa-laptop-house':
        return faLaptopHouse
        break
      case 'fas fa-paper-plane':
        return faPaperPlane
        break
      case 'fas fa-power-off':
        return faPowerOff
        break
      case 'fas fa-rss':
        return faRss
        break
      case 'fas fa-seedling':
        return faSeedling
        break
      case 'fas fa-signal':
        return faSignal
        break
      case 'fas fa-star':
        return faStar
        break
      case 'fas fa-music':
        return faMusic
        break
      case 'fas fa-play':
        return faPlay
        break
      case 'fas fa-volume-up':
        return faVolumeUp
        break
      default:
        return faSpa
    }
  }

  return (
    <Container>
      <IconContainer>
        <FontAwesomeIcon color="white" icon={getIcon()} size={width >= 992 ? 'lg' : '2x'} />
      </IconContainer>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </Container>
  )
}

CardBenefit.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconUrl: PropTypes.any.isRequired,
}

export default CardBenefit
