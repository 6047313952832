import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isEqual } from 'lodash'

import {
  Container,
  InputContainer,
  Label,
  Input,
  Overlay,
  DropdownListContainer,
  DropdownItemLabelContainer,
  DropdownItem,
  SeparatorLine,
  DropdownItemContainer,
  ArrowImage,
} from './styles'

type Props = {
  label: string
  placeholder?: string
  disabled?: boolean
  showDropdownList?: boolean
  dropdownList: string[]
  onInputClick: () => void
  selectedItem: string
  useAcademyColor?: boolean
  onDropdownItemSelect: (value: string) => void
  id?: string
}

const DropdownInput = ({
  showDropdownList = false,
  disabled = false,
  useAcademyColor,
  selectedItem = '',
  label = '',
  placeholder = '',
  dropdownList = [],
  id = 'dropdownSelectedItem',
  onInputClick,
  onDropdownItemSelect,
}: Props) => {
  const [highlightDropdownItem, setHighlightDropdownItem] = useState({ highlight: false, index: 0 })
  const handleInputClick = () => !disabled && onInputClick()

  const handleDropdownItemClick = (value: string) => {
    onDropdownItemSelect(value)
  }

  const handleDropdownItemMouseDown = (index: number) => {
    setHighlightDropdownItem({ highlight: true, index: index })
  }

  const handleDropdownItemMouseUp = (index: number) => {
    setHighlightDropdownItem({ highlight: false, index: index })
  }

  return (
    <Container>
      <InputContainer
        small={isEmpty(label)}
        disabled={disabled}
        onClick={handleInputClick}
        highlightBorder={showDropdownList}
        useAcademyColor={useAcademyColor}
      >
        {disabled && <Overlay />}
        <div style={{ width: '80%' }}>
          {!isEmpty(label) && <Label>{label}</Label>}
          <Input placeholder={placeholder} disabled={true} value={selectedItem} id={id} />
        </div>
        <ArrowImage>
          <img src={require('./images/arrow-down.png')} />
        </ArrowImage>
      </InputContainer>
      {showDropdownList && (
        <DropdownListContainer>
          {dropdownList?.map((item: string, index) => (
            <DropdownItemContainer
              onMouseDown={() => handleDropdownItemMouseDown(index)}
              onMouseUp={() => handleDropdownItemMouseUp(index)}
              key={index}
              onClick={() => handleDropdownItemClick(item)}
              highlightItemSelected={isEqual(index, highlightDropdownItem.index) && highlightDropdownItem.highlight}
            >
              <SeparatorLine hidden={index < 1} />
              <DropdownItemLabelContainer>
                <DropdownItem
                  highlightItemSelected={isEqual(index, highlightDropdownItem.index) && highlightDropdownItem.highlight}
                >
                  {item}
                </DropdownItem>
              </DropdownItemLabelContainer>
            </DropdownItemContainer>
          ))}
        </DropdownListContainer>
      )}
    </Container>
  )
}

DropdownInput.defaultProps = {
  disabled: false,
  showDropdownList: false,
  selectedItem: '',
  dropdownList: [],
  placeholder: '',
  label: '',
}

DropdownInput.propTypes = {
  placeholder: PropTypes.string,
  onInputClick: PropTypes.func.isRequired,
  onDropdownItemSelect: PropTypes.func.isRequired,
  label: PropTypes.string,
  dropdownList: PropTypes.array,
  disabled: PropTypes.bool,
  showDropdownList: PropTypes.bool,
  selectedItem: PropTypes.string,
}

export default DropdownInput
