import styled from 'styled-components'
import { getLightenColor } from '../../../helpers/lightenColor'

type InterProps = {
  theme: any
}

type Props = {
  isDesktop: boolean
}

export const Layout = styled.div`
  p {
    margin: 0;
  }
`

export const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: ${(props: Props) => (props.isDesktop ? 'row' : 'column')};
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 70px 0 30px;
`

export const ConfettiIcon = styled.img`
  height: 50px;
  width: 50px;
`

export const Title = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  padding: 20px 0;
`

export const DataAndButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props: Props) => (props.isDesktop ? '50px' : '0')};
`

export const Summary = styled.div`
  background-color: ${(props: InterProps) => getLightenColor(props.theme.color_main)};
  padding: 15px;
  border-radius: 8px;
`

export const SummaryTitle = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: #737373;
`

export const ProgramTitle = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #241d29;
  padding: 10px 0;
`

export const CustomerDetailsDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #241d29;
  padding-top: 20px;
`

export const LmsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const StudentDashboardActionButton = styled.button`
  padding: 0 24px;
  height: 35px;
  background-color: white;
  border-radius: 8px;
  border: solid 1px #241d29;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #241d29;
  cursor: pointer;
`

export const DescriptionText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  justify-conten: ${(props: Props) => (props.isDesktop ? 'flex-end' : 'center')};
`
