import styled, { keyframes } from 'styled-components'

const loading = keyframes`
from {
    transform: translateX(0);
  }

  to {
    transform: translateX(275px);
  }
`

export const Container = styled.div`
  width: 281.54px;
  height: 314.14px;
  position: relative;

  box-shadow: -2.9729px 0px 14.8645px rgba(0, 0, 0, 0.1), 2.9729px 2.9729px 7.43225px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
`

export const Banner = styled.div`
  height: 88.39px;
  background-color: #f2f2f2;
  border-radius: 15px 15px 0px 0px;

  background-size: cover !important;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  border-radius: 0px 0px 15px 15px;

  height: 226px;

  padding: 0 15px;
`

export const Title = styled.div`
  height: 10px;
  width: 30%;
  background-color: #f2f2f2;

  margin: 20px 0;
  overflow: hidden;

  position: relative;

  ::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
    animation: ${loading} 1s infinite linear;
  }
`

export const Description = styled.div`
  height: 10px;
  width: 100%;
  background-color: #f2f2f2;

  margin-top: 10px;

  overflow: hidden;

  position: relative;

  ::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
    animation: ${loading} 1s infinite linear;
  }
`

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding-bottom: 10px;

  div {
    height: 10px;
    width: 20px;
    background-color: #f2f2f2;

    overflow: hidden;

    position: relative;

    ::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 100%;
      background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
      animation: ${loading} 2s infinite linear;
    }
  }
`
