import styled from 'styled-components'

type Props = {
  theme: any
}

export const Container = styled.div`
  background-color: #f0f0f0;
  padding: 63px 220px 43px 220px;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 93px;
`

export const AcademyLogo = styled.div`
  width: 139px;

  img {
    width: 100%;
  }
`

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #bfbfbf;
  margin-top: 55px;
  @media (min-width: 768px) {
    margin-top: 23px;
  }
`

export const FooterItemTitle = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #241d29;
  margin-bottom: 40px;
`

export const DevelopedBy = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;

  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #241d29;
    margin-right: 10px;
  }

  img {
    cursor: pointer;
  }
`

export const LinkRef = styled.a`
  text-decoration: none;
`

export const LoginButtons = styled.div`
  display: flex;
  a {
    margin-right: 16px;
  }
`

export const ButtonLogin = styled.a`
  white-space: nowrap;
  text-align: center;
  background-color: ${({ theme }: Props) => theme.color_main};
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 60px;
  color: white;
  text-decoration: none;
  :hover {
    box-shadow: 0 4px 22px 0 rgba(0, 0, 0, 0.24);
  }
`

export const MobileContainer = styled.div`
  background-color: #f0f0f0;
`

export const MobileContent = styled.div`
  padding: 30px 46px 71.7px 48px;
`
