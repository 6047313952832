import styled, { css } from 'styled-components'

type Props = {
  small: boolean
}

export const CardContainer = styled.div`
  ${(props: Props) =>
    props.small
      ? css`
          width: 281.54px;
          height: 314.14px;
        `
      : css`
          width: 384.59px;
          height: 374.86px;
        `};

  margin-bottom: 30px;
  box-shadow: -2.9729px 0px 14.8645px rgba(0, 0, 0, 0.1), 2.9729px 2.9729px 7.43225px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;

  @media (min-width: 992px) {
    margin-bottom: 0px;
  }
`

export const BannerImage = styled.div`
  ${(props: Props) =>
    props.small
      ? css`
          height: 88.39px;
        `
      : css`
          height: 118.93px;
        `};
  border-radius: 15px 15px 0px 0px;
  background-size: cover !important;
`

export const Title = styled.p`
  ${(props: Props) =>
    props.small
      ? css`
          font-size: 17.8374px;
          line-height: 21px;
        `
      : css`
          font-size: 22px;
          line-height: 29px;
        `};

  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #000000;
`

export const Label = styled.div`
  ${(props: Props) =>
    props.small
      ? css`
          font-size: 8.9187px;
          line-height: 10px;
        `
      : css`
          font-size: 12px;
          line-height: 14px;
        `};
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  color: #000000;
`

export const TitleContainer = styled.p`
  ${(props: Props) =>
    props.small
      ? css`
          width: 197px;
        `
      : css`
          width: 268px;
        `};
  margin-top: -15px;
`

export const Description = styled.p`
  color: black;
  font-style: normal;
  font-weight: normal;
  font-family: 'Roboto';
  ${(props: Props) =>
    props.small
      ? css`
          font-size: 11.8916px;
          line-height: 14px;
        `
      : css`
          font-size: 14px;
          line-height: 17px;
        `};
`

export const CardContentContainer = styled.div`
  ${(props: Props) =>
    props.small
      ? css`
          padding: 21px 28px;
        `
      : css`
          padding: 20px 43px;
        `};
  display: flex;
  flex-direction: column;
`

export const CardTextContainer = styled.div`
  ${(props: Props) =>
    props.small
      ? css`
          height: 160px;
        `
      : css`
          height: 180px;
        `};
`

export const DescriptionContainer = styled.div`
  margin-top: -10px;
`
