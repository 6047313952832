import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { BodyTitle } from '..'
import { FormInput, InputError } from './styles'

type Props = {
  label?: string
  error?: string
  value?: string
  ref?: any
  type?: string
  id?: string
  placeholder?: string
  onCopy?: (event: any) => void
  onCut?: (event: any) => void
  onDrag?: (event: any) => void
  onDrop?: (event: any) => void
  onPaste?: (event: any) => void
  autoComplete?: string
  onChange: any
}

const TextInput = ({ label, value, error, ...props }: Props) => (
  <div>
    <BodyTitle>{label}</BodyTitle>
    <FormInput type="text" required value={value} {...props} />
    <InputError>{!isEmpty(value) && error}</InputError>
  </div>
)

TextInput.defaultProps = {
  label: undefined,
  error: undefined,
}

TextInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default TextInput
