import styled from 'styled-components'
import { lighten } from 'polished'
import { COLORS } from '..'

type Props = {
  filled?: boolean
  theme?: any
  color?: any
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 150px 10px 30px 0;

  @media (min-width: 992px) {
    margin: 0;
  }
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`

export const LineContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const StepIcon = styled.svg`
  height: 24px;
  width: 24px;
`

export const IconDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: ${(props: Props) => props.color};
`

export const Description = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  left: 30px;
  margin: 37px 0 0;
  color: ${(props: Props) => props.theme.color_main};
  position: absolute;
`

export const Line = styled.div`
  height: 1.5px;
  width: 100%;
  background: ${({ filled, theme }: Props) => (filled ? lighten(0.1, theme.color_main) : '#D9D9D9')};
`
