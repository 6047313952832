import styled from 'styled-components'

type Props = {
  theme: any
}

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  width: 393px;
  height: 237.68px;
  box-shadow: -4px 0px 20px rgba(0, 0, 0, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 130px;

  @media (min-width: 992px) {
    margin-bottom: 0px;
  }
`

export const InstructorAvatar = styled.div`
  width: 187px;
  height: 187px;
  border: 5px solid ${(props: Props) => props.theme.color_main};
  position: absolute;
  top: -110px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background-size: cover !important;
`

export const InstructorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
`

export const InstructorDescription = styled.div`
  margin-top: -35px;
`

export const ActionButtonContainer = styled.div`
  margin-top: 10px;
`
