import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import '../../../../i18n'
import { Container, ProgramInfo, ProgramTitle, Amount, Month, Day, EventType, EventTypeContainer } from './styles'

type PaymentInfo = {
  title: string
  amount: string
  month: string
  day: string
  eventType: string
  free?: boolean
}

const PaymentInfo = ({ title, amount, month, day, eventType, free = false }: PaymentInfo) => {
  const { t } = useTranslation()

  return (
    <Container>
      <ProgramInfo>
        <div>
          <ProgramTitle>{title}</ProgramTitle>
          <Amount>{free ? t('paymentsInfo.free') : amount}</Amount>
        </div>
        <Month>{month}</Month>
        <Day>{day}</Day>
      </ProgramInfo>
      <EventTypeContainer>
        <EventType>{eventType}</EventType>
      </EventTypeContainer>
    </Container>
  )
}

PaymentInfo.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  free: PropTypes.bool,
}

PaymentInfo.defaultProps = {
  free: false,
}

export default PaymentInfo
