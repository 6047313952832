import esTranslation from './es/translation.json'
import enTranslation from './en/translation.json'

const resources = {
  es: {
    translation: esTranslation,
  },
  en: {
    translation: enTranslation,
  },
}

export default resources
