import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { BodyText } from '../../Typography'

type Props = {
  theme: any
}

export const LinkRef = styled(Link)`
  text-decoration: none;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 33px;
  border: 1px solid ${(props: Props) => props.theme.color_main};
  box-sizing: border-box;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
`
export const Label = styled(BodyText)`
  color: ${(props: Props) => props.theme.color_main};
  font-weight: bold;
`
