import Loadable from 'react-loadable'

import PaymentSuccess from '../screens/Payments/PaymentSuccess'
import PaymentError from '../screens/Payments/PaymentError'
import RouteNotFound from '../screens/RouteNotFound'
import AcademyNotFound from '../screens/AcademyNotFound'
import { Spinner } from '../components/kit'

const Home = Loadable({
  loader: () => import('../screens/Home'),
  loading: Spinner,
})

const Checkout = Loadable({
  loader: () => import('../screens/Payments/Checkout'),
  loading: Spinner,
})

const Program = Loadable({
  loader: () => import('../screens/Program'),
  loading: Spinner,
})

const Products = Loadable({
  loader: () => import('../screens/Products'),
  loading: Spinner,
})

const Instructor = Loadable({
  loader: () => import('../screens/Challenger'),
  loading: Spinner,
})

const Facilitators = Loadable({
  loader: () => import('../screens/Facilitators'),
  loading: Spinner,
})

const Routes = [
  {
    component: Home,
    path: '/home',
  },
  {
    component: Products,
    path: '/products',
  },
  {
    component: Facilitators,
    path: '/instructors',
  },
  {
    component: AcademyNotFound,
    path: '/400',
  },
  {
    component: RouteNotFound,
    path: '/404',
  },
  {
    component: Instructor,
    path: '/instructor/:id',
  },
  {
    component: Checkout,
    path: '/product/:programId/edition/:editionId/price/:priceId/:programSlug',
  },
  {
    component: Checkout,
    path: '/:programSlug/edition/:editionId',
  },
  {
    component: Checkout,
    path: '/order/:orderIdParam',
  },
  {
    component: Program,
    path: '/product/:programSlug',
  },
  {
    component: Products,
    path: '/products/:typeId',
  },
  {
    component: PaymentError,
    path: '/checkout/error',
  },
  {
    component: PaymentSuccess,
    path: '/checkout/success',
  },
]

export default Routes
