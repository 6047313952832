import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 40px;

  @media (min-width: 992px) {
    display: block;
    padding-top: 0;
  }
`

export const IconContainer = styled.div`
  @media (min-width: 992px) {
    position: absolute;
    margin-top: 3px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 13px;

  @media (min-width: 992px) {
    margin-left: 40px;
    padding: 0;
    display: block;
  }
`

export const Title = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;

  color: #ffffff;

  max-width: 269px;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  @media (min-width: 992px) {
    text-align: left;

    font-size: 24px;
    line-height: 28px;
  }
`

export const Description = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #ffffff;

  padding-top: 18px;

  max-width: 243.12px;

  @media (min-width: 992px) {
    max-width: 287px;
    text-align: left;

    font-size: 16px;
    line-height: 19px;
  }
`
