import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'
import { useTranslation } from 'react-i18next'

import { BodyTitle, BodyText, MainButton } from '../..'
import { Container, StartDateContainer, AmountContainer, ActionButtonContainer } from './styles'

type CardOffersProps = {
  title: string
  description: string
  currency: string
  amount: number | undefined
  onClick: () => void
  featured: boolean
}

const CardOffers = ({ title, description, currency, amount, onClick, featured }: CardOffersProps) => {
  const { t } = useTranslation()

  return (
    <Container featured={featured}>
      <div>
        <BodyTitle negative>{title}</BodyTitle>
      </div>
      <StartDateContainer>
        <BodyText negative>{description}</BodyText>
      </StartDateContainer>
      {!isNil(amount) && (
        <AmountContainer>
          <BodyTitle negative>{currency === 'AR' ? `$ ${amount}` : `US$ ${amount}`}</BodyTitle>
        </AmountContainer>
      )}
      <ActionButtonContainer>
        <MainButton onClick={onClick} label={t('cardOffer.subscribe')} negative={featured} />
      </ActionButtonContainer>
    </Container>
  )
}

CardOffers.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  featured: PropTypes.number.isRequired,
}

CardOffers.defaultProps = {
  amount: null,
}

export default CardOffers
