import React from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

import { Hero } from '..'
import { FaqsList, Description, Container } from './styles'
import FaqElement from './FaqElement'

type FaqsProps = {
  faqs: any
  faqsTitle: string
  faqsSubtitle: string
}

const Faqs = ({ faqs, faqsTitle, faqsSubtitle }: FaqsProps) => {
  return (
    <Container>
      <div>
        <Hero>{faqsTitle}</Hero>
      </div>
      <div>
        <Description>{faqsSubtitle}</Description>
      </div>
      <FaqsList>
        <div>
          {!isNil(faqs) &&
            faqs.map((item: any, i: number) => <FaqElement key={i} question={item.question} answer={item.answer} />)}
        </div>
      </FaqsList>
    </Container>
  )
}

Faqs.propTypes = {
  faqs: PropTypes.array.isRequired,
  faqsTitle: PropTypes.string.isRequired,
  faqsSubtitle: PropTypes.string.isRequired,
}

export default Faqs
