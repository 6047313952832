import React from 'react'

import { useTranslation } from 'react-i18next'
import * as S from './styles'

const AcademyInactiveError = () => {
  const { t } = useTranslation()

  const ERROR_BACKGROUND = require('../../static/error-background.svg')
  const ERROR_ICON = require('../../static/error-icon.svg')
  const LOGO_STANDARD = require('../../static/logo_standard.svg')

  return (
    <S.Container>
      <S.ErrorInfo>
        <p>{t('academyInactiveError.code')}</p>
        <S.MainInfo>
          <S.ErrorIcon src={ERROR_ICON} />
          <strong>{t('academyInactiveError.title')}</strong>
          <p>{t('academyInactiveError.description')}</p>
        </S.MainInfo>
        <S.EdutiveCta>
          <S.LogoStandard src={LOGO_STANDARD} />
          <p>
            {t('academyInactiveError.visit')}
            <a href="https://edutive.co">
              <strong> edutive.co</strong>
            </a>
          </p>
        </S.EdutiveCta>
      </S.ErrorInfo>
      <S.ErrorBackground src={ERROR_BACKGROUND} />
    </S.Container>
  )
}

export default AcademyInactiveError
